import { Box, Button, Collapse, Flex, Text, Image, Grid, GridItem, Center } from "@chakra-ui/react";
import {FaYoutube, FaLinkedinIn, FaFacebookF, FaInstagram} from 'react-icons/fa';
import TranslationContainer from "../languages/Translation/TranslationContainer";
import { Link } from "react-router-dom";

export default function Footer() {

    return (
        <Box borderTop={'1px solid #edf2f7'} shadow={'md'}>
            <Grid p={10} mx={'auto'} width={{base:"100%", lg: "1024px", xl: "1250px"}} templateColumns={'repeat(2, 1fr)'} rowGap={10}>
                <GridItem colSpan={{base: 2, md: 1}}>
                    <Text fontWeight={'bold'} textAlign={{base: 'center', sm: 'left'}} color={'gray.600'}><TranslationContainer translationKey="partners" /></Text>
                    <Flex mt={5} flexWrap={'wrap'} justify={{base: 'center', sm: 'flex-start'}} columnGap={8}>
                        <Box as={'button'}>
                            <Image alt={''} src={'/images/cine_loges.png'}  height={'60px'} filter={'grayscale(1)'} _hover={{filter: 'grayscale(0)'}} transitionDuration={'1s'} />
                        </Box>
                        <Box as={'button'}>
                            <Image alt={''} src={'/images/500_nocturnes.png'}  height={'60px'} filter={'grayscale(1)'} _hover={{filter: 'grayscale(0)'}} transitionDuration={'1s'} />
                        </Box>
                    </Flex>
                </GridItem>
                <GridItem colSpan={{base: 2, md: 1}}>
                    <Text fontWeight={'bold'} textAlign={{base: 'center', sm: 'left'}} color={'gray.600'}><TranslationContainer translationKey="sponsoring" /></Text>
                    <Flex mt={5} flexWrap={'wrap'} justify={{base: 'center', sm: 'flex-start'}} columnGap={5} rowGap={5}>
                        <Center as={'button'} boxSize={'60px'}>
                            <Image alt={''} src={'/images/Strasbourg_logo.png'} height={'60px'} filter={'grayscale(1)'} _hover={{filter: 'grayscale(0)'}} transitionDuration={'1s'} />
                        </Center>
                        <Center as={'button'} boxSize={'60px'}>
                            <Image alt={''} src={'/images/logo-asptt.png'} height={'60px'} filter={'grayscale(1)'} _hover={{filter: 'grayscale(0)'}} transitionDuration={'1s'} />
                        </Center>
                        <Center as={'button'} boxSize={'60px'}>
                            <Image alt={''} src={'/images/Logo_SIG_Strasbourg.svg.png'} height={'60px'} filter={'grayscale(1)'} _hover={{filter: 'grayscale(0)'}} transitionDuration={'1s'} />
                        </Center>
                        <Center as={'button'} boxSize={'60px'}>
                            <Image alt={''} src={'/images/Logo_FC_Sochaux_Montbéliard_2015.svg.png'} height={'60px'} filter={'grayscale(1)'} _hover={{filter: 'grayscale(0)'}} transitionDuration={'1s'} />
                        </Center>
                        <Center as={'button'} boxSize={'60px'}>
                            <Image alt={''} src={'/images/FC-Metz.svg.png'} height={'60px'} filter={'grayscale(1)'} _hover={{filter: 'grayscale(0)'}} transitionDuration={'1s'} />
                        </Center>
                        <Center as={'button'} boxSize={'60px'}>
                            <Image alt={''} src={'/images/Fcmbasket.png'} height={'60px'} filter={'grayscale(1)'} _hover={{filter: 'grayscale(0)'}} transitionDuration={'1s'} />
                        </Center>
                        <Center as={'button'} boxSize={'60px'}>
                            <Image alt={''} src={'/images/y-ehrlacher.png'} height={'50px'} filter={'grayscale(1)'} _hover={{filter: 'grayscale(0)'}} transitionDuration={'1s'} />
                        </Center>
                    </Flex>
                </GridItem>
            </Grid>
            <Box p={10} bg={'gray.50'} borderTop={'1px solid #edf2f7'}>
                <Box mx={'auto'} width={{base:"100%", lg: "1024px", xl: "1250px"}}>
                    <Flex justify={'center'} columnGap={5}>
                        <Box as={'button'} p={3} rounded={'full'} border={'1px solid black'} _hover={{backgroundColor: '#03b403', borderColor: '#03b403', color: 'white'}} onClick={() => window.open('https://www.facebook.com/sofitex.interim')}>
                            <FaFacebookF size={'1.2em'} />
                        </Box>
                        <Box as={'button'} p={3} rounded={'full'} border={'1px solid black'} _hover={{backgroundColor: '#03b403', borderColor: '#03b403', color: 'white'}} onClick={() => window.open('https://www.linkedin.com/company/sofitex-interim')}>
                            <FaLinkedinIn size={'1.2em'} />
                        </Box>
                        <Box as={'button'} p={3} rounded={'full'} border={'1px solid black'} _hover={{backgroundColor: '#03b403', borderColor: '#03b403', color: 'white'}} onClick={() => window.open('https://www.instagram.com/sofitex.interim/')}>
                            <FaInstagram size={'1.2em'} />
                        </Box>
                        <Box as={'button'} p={3} rounded={'full'} border={'1px solid black'} _hover={{backgroundColor: '#03b403', borderColor: '#03b403', color: 'white'}} onClick={() => window.open('https://www.youtube.com/channel/UCsrvQq-1eeYsYU7fxismpGw/videos')}>
                            <FaYoutube size={'1.2em'} />
                        </Box>
                    </Flex>
                </Box>
                <Text mt={8} mr={2} textAlign="center"><TranslationContainer translationKey="agencies" />&nbsp;:</Text>
                <Flex mt={1} flexFlow="row wrap" justify="center">
                    <Flex>
                        <Image width="25px" src={'/images/fr-flag.png'} alt="france" />
                        <Text as="button" fontWeight={'semibold'} _hover={{color: '#03b403'}} ml="2px" mr={4} onClick={() => window.open("https://www.sofitex.fr/")}><TranslationContainer translationKey="franceFooter" /></Text>
                    </Flex>
                    <Flex>
                        <Image width="25px" src={'/images/lux-flag.png'} alt="luxembourg" />
                        <Text as="button" fontWeight={'semibold'} _hover={{color: '#03b403'}} ml="2px" mr={4} onClick={() => window.open("https://www.sofitex.lu/")}><TranslationContainer translationKey="luxembourgFooter" /></Text>
                    </Flex>
                    <Flex alignItems={'center'}>
                        <Image width="25px" alt='' height="15px" src={'/images/flag-be.png'} />
                        <Text as="button" fontWeight={'semibold'} _hover={{color: '#03b403'}} ml="2px" mr={4} onClick={() => window.open("https://www.sofitex.be/")}><TranslationContainer translationKey="belgiqueFooter" /></Text>
                    </Flex>
                    <Flex>
                        <Image width="25px" src={'/images/ch-flag.png'} alt="suisse" />
                        <Text as="button" fontWeight={'semibold'} _hover={{color: '#03b403'}} ml="2px" mr={4} onClick={() => window.open("https://www.sofitex.ch/")}><TranslationContainer translationKey="suisseFooter" /></Text>
                    </Flex>
                    <Flex>
                        <Image width="25px" src={'/images/all-flag.png'} alt="allemagne" />
                        <Text as="button" fontWeight={'semibold'} _hover={{color: '#03b403'}} ml="2px" onClick={() => window.open("https://www.sofitex-zeitarbeit.de/")}><TranslationContainer translationKey="allemagneFooter" /></Text>
                    </Flex>
                </Flex>
                <Flex mt={8} columnGap={2} justify={'center'} flexWrap={'wrap'}>
                    <Box as={'button'} _hover={{color: '#03b403'}}><Link to={'/mentions_legales'}><TranslationContainer translationKey="legalInformations" /></Link></Box>
                    |
                    <Box as={'button'} _hover={{color: '#03b403'}}><Link to={'/politique_de_confidentialite'}><TranslationContainer translationKey="privacyPolicy" /></Link></Box>
                    |
                    <Box as={'button'} _hover={{color: '#03b403'}} onClick={() => window.open('https://rse.sofitex.fr')}><TranslationContainer translationKey="csr" /></Box>
                </Flex>
            </Box>
        </Box>
    )

}