import { Box, Flex, Button, Grid, GridItem, Image, Center, Text } from '@chakra-ui/react'
import Header from '../components/Header'
import {BsArrowRight} from 'react-icons/bs'
import Footer from '../components/Footer'
import { useEffect } from 'react';
import TranslationContainer from '../languages/Translation/TranslationContainer';

export default function Solutions() {

  useEffect(() => {
    window.scrollTo(0,0);
  })

  return (
    <>
      {/* <Head>
        <title>Create Next App</title>
        <meta name="description" content="Generated by create next app" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head> */}
      <Header page='solutions' />
      <main>
        {/* <Box minHeight={{base: 'calc(100vh - 64px)', md: 'calc(100vh - 73px)'}}>
        </Box> */}
        <Center py={10} minHeight={{base: 'calc(100vh - 64px)', md: 'calc(100vh - 73px)'}} bg={'#edf2f7'}>
          <Grid templateColumns={'repeat(2, 1fr)'} width={{base:"100%", lg: "1024px", xl: "1250px"}}>
            <GridItem colSpan={{base: 2, md: 1}} px={5}>
              <Image mx={{base: 'auto', md: 0}} alt={''} src={'/images/logo_mysofitex.png'} width={{base: '300px', md: '400px'}} />
              <Image mt={5} display={{base: 'block', md: 'none'}} width={'100%'} alt={''} src={'/images/photo_mysofitex.png'} />
              <Text my={5} fontSize={'xl'}>
                <strong>MySofitex</strong> <TranslationContainer translationKey="mysofitexText1" />
              </Text>
              <Text fontSize={'xl'}>
                <TranslationContainer translationKey="mysofitexText2" />
              </Text>
              <Flex mt={10} columnGap={5}>
                <Box as={'button'} onClick={() => window.open('https://play.google.com/store/apps/details?id=com.sofitex.mysofitex')}>
                  <Image alt={''} src={'/images/img_playstore.png'} width={'200px'} />
                </Box>
                <Box as={'button'} onClick={() => window.open('https://apps.apple.com/fr/app/mysofitex/id1572604424')}>
                  <Image alt={''} src={'/images/img_appstore.png'} width={'200px'} />
                </Box>
              </Flex>
              <Button mt={10} variant={'outline'} borderColor={'black'} size={'lg'} rounded={'3xl'} _hover={{backgroundColor: 'black', color: 'white'}} rightIcon={<BsArrowRight />} onClick={() => window.open('https://mysofitex.fr')}><TranslationContainer translationKey="knowMore" /></Button>
            </GridItem>
            <GridItem display={{base: 'none', md: 'block'}} colSpan={{base: 2, md: 1}} rowStart={1} colStart={{base: 1, md: 2}}>
              <Image width={'100%'} alt={''} src={'/images/photo_mysofitex.png'} />
            </GridItem>
          </Grid>
        </Center>
        {/* <Box width={{base:"100%", lg: "1024px", xl: "1250px"}} mx={'auto'} height={'4px'} bg={'#03b403'}></Box> */}
        <Center py={10} width={{base:"100%", lg: "1024px", xl: "1250px"}} mx={'auto'} minHeight={{base: 'calc(100vh - 64px)', md: 'calc(100vh - 73px)'}}>
          <Grid templateColumns={'repeat(2, 1fr)'} width={'100%'}>
            <GridItem display={{base: 'none', md: 'block'}} colSpan={{base: 2, md: 1}}>
              <Image width={'100%'} alt={''}  src={'/images/photo_sofitex_online.jpg'} />
            </GridItem>
            <GridItem colSpan={{base: 2, md: 1}} px={5}>
              <Image mt={2} mx={{base: 'auto', md: 0}} alt={''} src={'/images/logo_sofitexonline.png'} width={'450px'} />
              <Image mt={5} display={{base: 'block', md: 'none'}} width={'100%'} alt={''} src={'/images/photo_sofitex_online.jpg'} />
              <Text my={5} fontSize={'xl'}>
                <strong>Sofitex Online</strong> <TranslationContainer translationKey="sofitexOnlineText1" />
              </Text>
              <Text fontSize={'xl'}>
                <TranslationContainer translationKey="sofitexOnlineText2" />
              </Text>
              <Flex mt={10} justifyContent={'flex-end'} pr={5}>
                <Button size={'lg'} rounded={'3xl'} rightIcon={<BsArrowRight />} onClick={() => window.open('https://sofitex-online.com')}><TranslationContainer translationKey="knowMore" /></Button>
              </Flex>
            </GridItem>
          </Grid>
        </Center>
        {/* <Box width={{base:"100%", lg: "1024px", xl: "1250px"}} mx={'auto'} height={'4px'} bg={'#03b403'}></Box> */}
        <Center pt={10} pb={20} minHeight={{base: 'calc(100vh - 64px)', md: 'calc(100vh - 73px)'}} bg={'#edf2f7'}>
          {/* <Grid px={{base: 5, md: 20}} templateColumns={'repeat(2, 1fr)'} width={{base:"100%", lg: "1024px", xl: "1250px"}} columnGap={{md: 10, lg: 20, xl: 40}} rowGap={10}> */}
          <Grid px={{base: 0, md: 20}} templateColumns={'repeat(2, 1fr)'} width={{base:"100%", lg: "1024px", xl: "1250px"}}>
            <GridItem py={10} px={{base: 8, md: 10}} colSpan={{base: 2, lg: 1}} bg={'white'} fontSize={'lg'} shadow={'lg'}>
              <Image maxHeight={{base: '65px', lg: '65px'}} alt={''} src={'/images/logo_sofitex_inside.png'} />
              <Text my={3}><TranslationContainer translationKey="sofitexInsideText1" /> <Box as={'span'} fontWeight={'bold'} color={'#03b403'}>Sofitex Inside</Box>.</Text>
              <Text><TranslationContainer translationKey="sofitexInsideText2" /></Text>
              <Flex mt={10} justifyContent={'flex-end'}>
                <Button size={'lg'} rounded={'3xl'}  variant={'outline'} borderColor={'black'} _hover={{backgroundColor: 'black', color: 'white'}} rightIcon={<BsArrowRight />} onClick={() => window.open('https://inside.sofitex.fr')}><TranslationContainer translationKey="knowMore" /></Button>
              </Flex>
            </GridItem>
            {/* <GridItem py={10} px={{base: 8, md: 10}} colSpan={{base: 2, md: 1}} bg={'white'} fontSize={'lg'} rounded={'md'} shadow={'lg'}> */}
            <GridItem colSpan={{base: 2, lg: 1}} bg={'white'} fontSize={'lg'} rounded={'md'} shadow={'lg'}>
              {/* <Image maxHeight={{base: '65px', lg: '65px'}} alt={''} src={'/images/logo_sofitex_jobnight.png'} />
              <Text my={3}><TranslationContainer translationKey="sofitexJobNightText1" /> <Box as={'span'} fontWeight={'bold'} color={'#03b403'}>Sofitex JobNight</Box> <TranslationContainer translationKey="sofitexJobNightText2" /></Text>
              <Text><TranslationContainer translationKey="sofitexJobNightText3" /></Text>
              <Flex mt={10} justifyContent={'flex-end'}>
                <Button size={'lg'} rounded={'3xl'}  variant={'outline'} borderColor={'black'} _hover={{backgroundColor: 'black', color: 'white'}} rightIcon={<BsArrowRight />} onClick={() => window.open('https://jobnight.sofitex.fr')}><TranslationContainer translationKey="knowMore" /></Button>
              </Flex> */}
              <Image src='/images/office_implant.jpg' alt={'phtoto_implant'} height={'100%'} width={'100%'}  />
            </GridItem>
          </Grid>
        </Center>
        <Footer />
      </main>
    </>
  )
}