import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Brands from "../pages/Brands";
import Solutions from "../pages/Solutions";
import Hiring from "../pages/Hiring";
import LegalInformation from "../pages/LegalInformation";
import PrivacyPolicy from "../pages/PrivacyPolicy";

function Routing() {

    return (
        <Routes>
            {/*Public Routes */}
            <Route exact path="/" element={<Home/>} />
            <Route exact path="/nos_marques" element={<Brands/>} />
            <Route exact path="/nos_solutions" element={<Solutions/>} />
            <Route exact path="/nous_recrutons" element={<Hiring/>} />
            <Route exact path="/mentions_legales" element={<LegalInformation/>} />
            <Route exact path="/politique_de_confidentialite" element={<PrivacyPolicy/>} />
        </Routes>
    )

}

export default Routing;