import ApiBeetween from './ApiBeetween';
import apiSofitexGroup from './ApiSofitexGroup';

const AxiosCenter = {

    // Requête GET
    getOffers(){
        return apiSofitexGroup({
            method: 'get',
            url: '/offers',
        });
    },

    getOffersByFilter(data){
        return apiSofitexGroup({
            method: 'post',
            url: '/offers',
            data: data
        });
    },

    postCandidature(data){
        return ApiBeetween({
            method: 'post',
            data: data
        });
    },

}

export default AxiosCenter;
