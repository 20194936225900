import './App.css';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { ToastContainer } from 'react-toastify';
import Routes from './routing/Routing';
import { BrowserRouter as Router } from 'react-router-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { rootReducer } from './rootReducer';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  const breakpoints = {
    xs: '480px',
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
  }


  const store = createStore(rootReducer);

  const theme = extendTheme({
    breakpoints,
    colors: {
      sofitex: {
        500: "#03b403"
      }
    }
  })

  return (
    <Provider store={store}> 
      <ChakraProvider theme={theme}>
        <Router>
          <Routes/>
        </Router> 
        <ToastContainer 
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          theme="dark"
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </ChakraProvider>
    </Provider>
  );
}

export default App;
