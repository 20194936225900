import { Box, Flex, Button, Heading, FormControl, Text, FormLabel, SimpleGrid, Grid, GridItem, Center, Spinner, FormErrorMessage, Input, Checkbox } from '@chakra-ui/react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Form, Formik, Field } from 'formik';
import ReactSelect from 'react-select';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { BsGeoAlt, BsFileText, BsClock } from 'react-icons/bs';
import { AiOutlineNumber } from 'react-icons/ai';
import { RiMailSendLine } from 'react-icons/ri';
import { Component } from 'react';
import AxiosCenter from '../axios/AxiosCenter';
import Error from '../axios/toasts/Error';
import ReactPaginate from 'react-paginate';
import TranslationContainer from '../languages/Translation/TranslationContainer';
import Success from '../axios/toasts/Success';
import * as yup from 'yup';

export default class Hiring extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      offers: [],
      offset: 0,
      perPage: 10,
      currentPage: 0,
      paginatedData: [],
      pageCount: 1,
      offer: {},
      reinitialize: false,
      visible: false,
      candidatureLoading: false,
      search: true,
    }
  }

  receivedData() {
    const data = this.state.offers;
    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    const paginatedData = slice.map((offer, index) => (
      <Grid key={index} templateColumns={{base: 'repeat(6, 1fr)', md: 'repeat(8, 1fr)'}} rowGap={10} py={10} px={5} bg={index % 2 === 0 ? 'white': '#edf2f7'}>
        <GridItem colSpan={4}>
          <Box as={'button'} textAlign={'left'} variant={'link'} textDecoration={'underline'} color={'#03b403'}  fontSize={'lg'} fontWeight={'semibold'} _hover={{color: '#00ed00'}} onClick={() => this.openJobForm(offer)}>{offer.title}</Box>
        </GridItem>
        <GridItem colSpan={2}>
          <Text textAlign={{base: 'end', md: 'left'}} color={'gray.600'} fontSize={'lg'} fontWeight={'bold'}>{offer.type}</Text>
        </GridItem>
        <GridItem colSpan={{base: 6, md: 2}}>
          <Flex columnGap={2} alignItems={'center'}>
            <Box minWidth={'15px'}>
              <FaMapMarkerAlt size={'1.2em'} color='#03b403' />
            </Box>
            <Text fontSize={'lg'}>{offer.display}, {offer.region1}, {offer.country_code}</Text>
          </Flex>
        </GridItem>
      </Grid>
    ));

    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),

      paginatedData
    });
    
  }

  handlePageClick = (e) => {

    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
        visible: false,
        offer: {},
        reinitialize: !this.state.reinitialize,
      },
      () => {
        this.receivedData();
      }
    );

    const element = document.getElementById('list-head');
    element.scrollIntoView({ behavior: 'smooth' });
  };

  handleReset() {
    this.setState({
      isLoaded: false,
      visible: false,
      offer: {}
    })
    AxiosCenter.getOffers()
    .then((response) => {
      console.log(response.data)
      this.setState({
        offers: response.data,
        currentPage: 0,
        offset: 0,
        isLoaded: true
      }, () => this.receivedData())
    })
    .catch(error => {
      this.setState({
        isLoaded: true
      })
      Error(<TranslationContainer translationKey="errorMessage" />)
    })
  }

  submitFilter(values) {
    this.setState({
      isLoaded: false,
      visible: false,
      offer: {}
    })

    let data = {
      country: values.country.value,
      localisation: values.localisation.value,
      qualification: values.qualification.value,
      type: values.type.value,
    }

    AxiosCenter.getOffersByFilter(data)
    .then((response) => {
      console.log(response.data)
      this.setState({
        offers: response.data,
        currentPage: 0,
        offset: 0,
        isLoaded: true
      }, () => this.receivedData())
    })
    .catch(error => {
      this.setState({
        isLoaded: true
      })
      Error(<TranslationContainer translationKey="errorMessage" />)
    })
  }

  sendCandidature(values) {
    this.setState({
      candidatureLoading: true
    })

    var input = document.querySelector('input[type="file"]')
    var data = new FormData()
    data.append('data', input.files[0])
    data.append('wid', values.poste.value)
    data.append('firstname', values.prenom)
    data.append('lastname', values.nom)
    data.append('email', values.email)
    data.append('filenames', input.files[0].name)

    AxiosCenter.postCandidature(data)
    .then((response) => {
      Success("Candidature envoyée !")
      this.setState({
        candidatureLoading: false,
        reinitialize: !this.state.reinitialize,
        visible: false,
        offer: {}
      })
    })
    .catch((error) => {
      Error(<TranslationContainer translationKey="errorMessage" />)
      this.setState({
        candidatureLoading: false
      })
    })
  }

  openJobForm(offer) {
    const element = document.getElementById('offer-detail');
    this.setState({
      offer,
      visible: true
    }, () => element.scrollIntoView({ behavior: 'smooth' }))
  }

  componentDidMount() {

    window.scrollTo(0,0);

    AxiosCenter.getOffers()
    .then((response) => {
      console.log(response.data)
      this.setState({
        offers: response.data,
        isLoaded: true
      }, () => this.receivedData())
    })
    .catch(error => {
      this.setState({
        isLoaded: true
      })
      Error(<TranslationContainer translationKey="errorMessage" />)
    })
  }

  render() {

    const selectFlushedStyle = {
      placeholder: (base, state) => ({
        ...base,
        // fontWeight: "bold",
        color: "#2f2f2f",
      }),
      indicatorSeparator: (base, state) => ({
        ...base,
        backgroundColor: 'white',
      }),
      control: (base, state) => ({
        ...base,
        padding: '5px',
        borderRadius: '5px',
        border: state.isFocused ? "1px solid #03b403" : "1px solid #e2e8f0",
        boxShadow: "none",
        '&:hover': {
          //   borderBottom: "2px solid #00de00",
            boxShadow: "none"
        },
      }),
      singleValue: (base, state) => ({
        ...base,
        fontWeight: "normal",
      }),
      // multiValueLabel: (base, state) => ({
      //   ...base,
      //   fontWeight: "bold"
      // }),
      option: (base, state) => ({
        ...base,
        backgroundColor: state.isFocused ? "rgba(3, 252, 0, 0.2)" : "white",
        color: "black",
        '&:active': {
          backgroundColor: "rgba(3, 252, 0, 0.4)",
        }
      }),
      dropdownIndicator: (base, state) => ({
        ...base,
        color: '#03b403'
      })
    }
  
    const countryOptions = [
      {label: <TranslationContainer translationKey="allCountries" />, value: ''},
      {label: <TranslationContainer translationKey="franceFooter" />, value: 'FR'},
      {label: <TranslationContainer translationKey="luxembourgFooter" />, value: 'LU'},
      {label: <TranslationContainer translationKey="allemagneFooter" />, value: 'DE'},
      {label: <TranslationContainer translationKey="suisseFooter" />, value: 'CH'},
      {label: <TranslationContainer translationKey="belgiqueFooter" />, value: 'BE'},
    ]
  
    const localisationOptions = [
      {label: <TranslationContainer translationKey="allCountries" />, value: ''},
      {label: <TranslationContainer translationKey="franceFooter" />, value: 'FR'},
      {label: <TranslationContainer translationKey="luxembourgFooter" />, value: 'LU'},
      {label: <TranslationContainer translationKey="allemagneFooter" />, value: 'DE'},
      {label: <TranslationContainer translationKey="suisseFooter" />, value: 'CH'},
      {label: <TranslationContainer translationKey="belgiqueFooter" />, value: 'BE'},
    ]
  
    const jobOptions = [
      {label: <TranslationContainer translationKey="allCountries" />, value: ''},
      {label: <TranslationContainer translationKey="franceFooter" />, value: 'FR'},
      {label: <TranslationContainer translationKey="luxembourgFooter" />, value: 'LU'},
      {label: <TranslationContainer translationKey="allemagneFooter" />, value: 'DE'},
      {label: <TranslationContainer translationKey="suisseFooter" />, value: 'CH'},
      {label: <TranslationContainer translationKey="belgiqueFooter" />, value: 'BE'},
    ]
  
    const typeOptions = [
      {label: <TranslationContainer translationKey="allTypes" />, value: ''},
      {label: <TranslationContainer translationKey="cdi" />, value: 'CDI'},
      {label: <TranslationContainer translationKey="cdd" />, value: 'CDD'},
      {label: <TranslationContainer translationKey="alternance" />, value: 'Alternance'},
      {label: <TranslationContainer translationKey="stage" />, value: 'Stage'},
      {label: <TranslationContainer translationKey="interim" />, value: 'Intérim'},
    ]

    const filterInitialValues = {
      country: {label: <TranslationContainer translationKey="allCountries" />, value: ''},
      localisation: {label: <TranslationContainer translationKey="allCountries" />, value: ''},
      qualification: {label: <TranslationContainer translationKey="allCountries" />, value: ''},
      type: {label: <TranslationContainer translationKey="allTypes" />, value: ''}
    }

    const candidatureSchema = yup.object().shape({
      nom: yup
      .string()
      .label('nom')
      .required(<TranslationContainer translationKey="errorMandatoryName" />),
      prenom: yup
      .string()
      .label('prenom')
      .required(<TranslationContainer translationKey="errorMandatoryFirstName" />),
      email: yup
      .string()
      .label('email')
      .matches(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)/, () => <TranslationContainer translationKey="errorInvalidEmail" />)
      .required(<TranslationContainer translationKey="errorMandatoryEmail" />),
      phone: yup
      .string()
      .label('phone')
      .required(<TranslationContainer translationKey="errorMandatoryPhone" />),
      cv: yup
      .string()
      .label('cv')
      .required(<TranslationContainer translationKey="errorMandatoryCV" />),
      rgpd: yup
      .string()
      .label('rgpd')
      .matches(true, () => <TranslationContainer translationKey="errorMandatoryRGPD" />),
    });

    const monthName = [
      <TranslationContainer translationKey="jan" />, 
      <TranslationContainer translationKey="feb" />, 
      <TranslationContainer translationKey="mar" />, 
      <TranslationContainer translationKey="apr" />,
      <TranslationContainer translationKey="may" />,
      <TranslationContainer translationKey="jun" />,
      <TranslationContainer translationKey="jul" />,
      <TranslationContainer translationKey="aug" />,
      <TranslationContainer translationKey="sep" />,
      <TranslationContainer translationKey="oct" />,
      <TranslationContainer translationKey="nov" />,
      <TranslationContainer translationKey="dec" />,
    ]

    const {isLoaded, offers, offer, reinitialize, visible, candidatureLoading, search} = this.state;
  
    return (
      <>
        {/* <Head>
          <title>Create Next App</title>
          <meta name="description" content="Generated by create next app" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href="/favicon.ico" />
        </Head> */}
        <Header page='offers' />
        <main>
          <Box>
            <Flex className='bg-img-dark' px={{base: 5, lg: 20}} py={10} alignItems={'center'} minHeight={{base: 'calc(100vh - 64px)', md: 'calc(100vh - 73px)'}}>
              <Box className='animated-title'>
                <Heading mt={2} fontSize={{base: '4xl', lg: '7xl'}} color={'white'} fontStyle={'italic'}><TranslationContainer translationKey="hiringTitle" /></Heading>
              </Box>
            </Flex>
            <Box mx={'auto'} width={{base:"100%", lg: "1024px", xl: "1250px"}} px={{base: 4, md: 8}} pt={{base: 10, lg: 20}} pb={{base: 20, lg: 40}}>
              <Heading textAlign={'center'}><TranslationContainer translationKey="hiringText1" /><br/><TranslationContainer translationKey="hiringText2" /></Heading>
              <Box mt={{base: 20, lg: 40}}>
              <Formik
                initialValues = {filterInitialValues}
                onSubmit = {(values) => this.submitFilter(values)}
                onReset={() => this.handleReset()}
                enableReinitialize
              >
                {formikProps => (
                    <Form noValidate>
                      <SimpleGrid columns={{base: 1, lg: 2}} columnGap={10} rowGap={{base: 5, md: 10}}>
                        <Box>
                          <FormLabel ml={1}><TranslationContainer translationKey="country" /></FormLabel>
                          <ReactSelect
                            className="select-input"
                            id="country"
                            name="country"
                            isSearchable
                            isDisabled={!isLoaded}
                            placeholder={<TranslationContainer translationKey="allCountries" />}
                            options={countryOptions}
                            styles={selectFlushedStyle}
                            value={formikProps.values.country} 
                            onChange={selectedOptions => formikProps.setFieldValue('country', selectedOptions === null ? formikProps.values.country = '' :  selectedOptions)}
                          />
                        </Box>
                        <Box>
                          <FormLabel ml={1}><TranslationContainer translationKey="location" /></FormLabel>
                          <ReactSelect
                            className="select-input"
                            id="localisation"
                            name="localisation"
                            isSearchable
                            isDisabled={!isLoaded}
                            placeholder={<TranslationContainer translationKey="allLocations" />}
                            options={localisationOptions}
                            styles={selectFlushedStyle}
                            value={formikProps.values.localisation} 
                            onChange={selectedOptions => formikProps.setFieldValue('localisation', selectedOptions === null ? formikProps.values.localisation = '' :  selectedOptions)}
                          />
                        </Box>
                        <Box>
                          <FormLabel ml={1}><TranslationContainer translationKey="job" /></FormLabel>
                          <ReactSelect
                            className="select-input"
                            id="qualification"
                            name="qualification"
                            isSearchable
                            isDisabled={!isLoaded}
                            placeholder={<TranslationContainer translationKey="allJobs" />}
                            options={jobOptions}
                            styles={selectFlushedStyle}
                            value={formikProps.values.qualification} 
                            onChange={selectedOptions => formikProps.setFieldValue('qualification', selectedOptions === null ? formikProps.values.qualification = '' :  selectedOptions)}
                          />
                        </Box>
                        <Box>
                          <FormLabel ml={1}><TranslationContainer translationKey="contractType" /></FormLabel>
                          <ReactSelect
                            className="select-input"
                            id="type"
                            name="type"
                            isSearchable
                            isDisabled={!isLoaded}
                            placeholder={<TranslationContainer translationKey="allTypes" />}
                            options={typeOptions}
                            styles={selectFlushedStyle}
                            value={formikProps.values.type} 
                            onChange={selectedOptions => formikProps.setFieldValue('type', selectedOptions === null ? formikProps.values.type = '' :  selectedOptions)}
                          />
                        </Box>
                        <Box>
                          <Button type={'submit'} size={'lg'} width={'100%'} backgroundColor={'#03b403'} color={'white'} _hover={{border: '1px solid #03b403', backgroundColor: 'white', color: '#03b403'}} isDisabled={!isLoaded}><TranslationContainer translationKey="searchBtn" /></Button>
                          <Flex mt={5} mb={{base: 5, md: 0}} justify={'center'}>
                            <Button id="list-head" variant={'link'} textDecoration={'underline'} color={'#00ed00'} _hover={{color: '#03b403', textDecoration: 'underline'}} isDisabled={!isLoaded && search} onClick={() => formikProps.resetForm()}><TranslationContainer translationKey="cancelSearch" /></Button>
                          </Flex>
                        </Box>
                      </SimpleGrid>
                    </Form>                       
                  )}
                </Formik>
                {!isLoaded ?
                <Center width={'100%'} height={'400px'}>
                  <Spinner size={'lg'} color={'#03b403'} />
                </Center>
                :
                offers.length === 0 ?
                <Text py={40} textAlign={'center'} fontWeight={'semibold'} fontSize={'xl'}><TranslationContainer translationKey="noOffers" /></Text>
                :
                <>
                <Box display={{base: 'none', md: 'block'}}>
                  <Grid templateColumns={'repeat(8, 1fr)'} mt={20} py={10} px={5}>
                    <GridItem colSpan={4}><Text fontSize={'xl'} fontWeight={'semibold'}><TranslationContainer translationKey="poste" /></Text></GridItem>
                    <GridItem colSpan={2}><Text fontSize={'xl'} fontWeight={'semibold'}><TranslationContainer translationKey="type" /></Text></GridItem>
                    <GridItem colSpan={2}><Text fontSize={'xl'} fontWeight={'semibold'}><TranslationContainer translationKey="location" /></Text></GridItem>
                  </Grid>
                </Box>
                <div width="100%">
                  {this.state.paginatedData}
                  <Flex justifyContent="center" mt={10}>
                    <Box pt={1} pb={3} px={2}>
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        disabledClassName={"disabled"}
                        forcePage={this.state.currentPage}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </Box>
                  </Flex>
                </div>
                </>
                }
              </Box>
              <Box id="offer-detail" pt={20}>
                <Grid templateColumns={'repeat(2, 1fr)'} columnGap={{base: 0, md: 10}} hidden={!visible}>
                  <GridItem colSpan={{base: 2, lg: 1}}>
                    <Text className='font-sofitex' ml={{base: 3, md: 5}} fontSize="4xl" fontWeight="bold">{offer.title}</Text>
                    <Text className='font-sofitex' ml={{base: 3, md: 5}} fontSize="sm" color="gray.500" fontStyle="italic"><TranslationContainer translationKey="published" /> {new Date(offer.last_modification_date).getDate()} {monthName[new Date(offer.last_modification_date).getMonth()]} {new Date(offer.last_modification_date).getFullYear()}</Text>
                    <Grid templateColumns="repeat(4, 1fr)" mt={6} mb={10} borderTop="1px solid lightgray" borderBottom="1px solid lightgray">
                        <GridItem colSpan={{base: 4, md: 2}}>
                            <Flex my={4} pl={6} alignItems="center" width="100%">
                                <AiOutlineNumber color='gray' />
                                <Box className='font-sofitex' ml={4}>
                                    <Text fontSize={{base: "md", lg: "sm"}} color="#03b403"><TranslationContainer translationKey="reference" /></Text>
                                    <Text fontSize="lg">{offer.id}</Text>
                                </Box>
                            </Flex>
                        </GridItem>
                        <GridItem colSpan={{base: 4, md: 2}}>
                            <Flex my={4} pl={6} alignItems="center" width="100%">
                                <BsGeoAlt color='gray' />
                                <Box className='font-sofitex' ml={4}>
                                    <Text fontSize={{base: "md", lg: "sm"}} color="#03b403"><TranslationContainer translationKey="localisation" /></Text>
                                    <Text fontSize="lg">{offer.display}</Text>
                                </Box>
                            </Flex>
                        </GridItem>
                        <GridItem colSpan={{base: 4, md: 2}}>
                            <Flex my={4} pl={6} pr={2} alignItems="center" width="100%">
                                <Box className='font-sofitex' minWidth="16px">
                                    <BsFileText color='gray' />
                                </Box>
                                <Box ml={4}>
                                    <Text fontSize={{base: "md", lg: "sm"}} color="#03b403"><TranslationContainer translationKey="domaine" /></Text>
                                    <Text fontSize="lg">{offer.domaine}</Text>
                                </Box>
                            </Flex>
                        </GridItem>
                        <GridItem colSpan={{base: 4, md: 2}}>
                            <Flex my={4} pl={6} alignItems="center" width="100%">
                                <BsClock color='gray' />
                                <Box className='font-sofitex' ml={4}>
                                    <Text fontSize={{base: "md", lg: "sm"}} color="#03b403"><TranslationContainer translationKey="contractType" /></Text>
                                    <Text fontSize="lg">{offer.type}</Text>
                                </Box>
                            </Flex>
                        </GridItem>
                    </Grid>
                    <Box as={'h2'} ml={1} flex='1' textAlign='start' fontSize="2xl" fontWeight="bold">
                      <TranslationContainer translationKey="companyDescription" />
                    </Box>
                    <Box ml={2} mt={2} pb={10} dangerouslySetInnerHTML={{ __html: offer.company }}>                    
                    </Box>
                    <Box as={'h2'} ml={1} flex='1' textAlign='start' fontSize="2xl" fontWeight="bold">
                      <TranslationContainer translationKey="jobDescription" />
                    </Box>
                    <Box ml={2} mt={2} pb={10} dangerouslySetInnerHTML={{ __html: offer.mission }}>
                    </Box>
                    <Box as={'h2'} ml={1} flex='1' textAlign='start' fontSize="2xl" fontWeight="bold">
                      <TranslationContainer translationKey="profileDescription" />
                    </Box>
                    <Box ml={2} mt={2} pb={10} dangerouslySetInnerHTML={{ __html: offer.profile }}>
                    </Box>
                  </GridItem>
                  <GridItem colSpan={{base: 2, lg: 1}} px={{sm: 10, md: 20}}>
                    <Box rounded={'md'} bg={'#03b403'} p={4}>
                      <Flex mb={8} color="white" alignItems="center">
                        <Text fontSize={{base: "2xl", md: "3xl"}} fontWeight="bold"><TranslationContainer translationKey="applicationTitle" /></Text>
                      </Flex>
                      <Formik
                        initialValues = {{nom: '', prenom: '', email: '', phone: '', cv: '', rgpd: false, reinitialize: reinitialize}}
                        validationSchema = {candidatureSchema} 
                        onSubmit = {(values) => this.sendCandidature(values)}
                        enableReinitialize
                      >
                        {formikProps => (
                          <Form noValidate>
                            <FormControl isInvalid={formikProps.errors.nom && formikProps.touched.nom} mb={5}>
                                <Text className="font-sofitex" mb={0} fontSize="sm" fontWeight={'semibold'} color={'white'}><TranslationContainer translationKey="name" /></Text>
                                <Field
                                  id='nom'
                                  name="nom"
                                  className="font-sofitex"
                                >
                                    {({field, form, meta}) => 
                                    <Input 
                                      {...field} 
                                      type="text" 
                                      focusBorderColor="#00ed00"
                                      size="sm"
                                      rounded="md"
                                      bg={'white'}
                                      // variant="filled"
                                      width="100%"
                                      onChange={formikProps.handleChange("nom")}
                                    />
                                    }
                                </Field>
                                <FormErrorMessage className="font-sofitex ml-1">{formikProps.errors.nom}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={formikProps.errors.prenom && formikProps.touched.prenom} mb={5}>
                              <Text className="font-sofitex" mb={0} fontSize="sm" fontWeight={'semibold'} color={'white'}><TranslationContainer translationKey="firstName" /></Text>
                              <Field
                                  id='prenom'
                                  name="prenom"
                              >
                                  {({field, form, meta}) => 
                                  <Input 
                                      {...field} 
                                      type="text" 
                                      focusBorderColor="#00ed00"
                                      size="sm"
                                      rounded="md"
                                      bg={'white'}
                                      width="100%"
                                      onChange={e => {formikProps.handleChange(e)}}
                                  />
                                  }
                              </Field>
                              <FormErrorMessage className="font-sofitex ml-1">{formikProps.errors.prenom}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={formikProps.errors.email && formikProps.touched.email} mb={5}>
                                <Text className="font-sofitex" mb={0} fontSize="sm" fontWeight={'semibold'} color={'white'}><TranslationContainer translationKey="email" /></Text>
                                <Field
                                    id='email'
                                    name="email"
                                >
                                {({field, form, meta}) => 
                                    <Input 
                                    {...field} 
                                    type="email" 
                                    focusBorderColor="#00ed00"
                                    size="sm"
                                    rounded="md"
                                    bg={'white'}
                                    width="100%"
                                    onChange={e => {formikProps.handleChange(e)}}
                                />
                                }
                                </Field>
                                <FormErrorMessage className="font-sofitex ml-1">{formikProps.errors.email}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={formikProps.errors.phone && formikProps.touched.phone} mb={5}>
                                <Text className="font-sofitex" mb={0} fontSize="sm" fontWeight={'semibold'} color={'white'}><TranslationContainer translationKey="phoneNumber" /></Text>
                                <Field
                                    id='phone'
                                    name="phone"
                                >
                                {({field, form, meta}) => 
                                    <Input 
                                    {...field} 
                                    type="tel" 
                                    focusBorderColor="#00ed00"
                                    size="sm"
                                    rounded="md"
                                    bg={'white'}
                                    width="100%"
                                    onChange={e => {formikProps.handleChange(e)}}
                                />
                                }
                                </Field>
                                <FormErrorMessage className="font-sofitex ml-1">{formikProps.errors.phone}</FormErrorMessage>
                            </FormControl>
                            <Grid templateColumns="repeat(2, 1fr)" columnGap={5} mb={3}>
                              <GridItem colSpan={{base: 2, sm: 1}}>
                                <FormControl isInvalid={formikProps.errors.cv && formikProps.touched.cv}>
                                    <Text className="font-sofitex" mb={1} fontSize="sm" fontWeight={'semibold'} color={'white'}><TranslationContainer translationKey="cv" /></Text>
                                    <Field
                                        id='cv'
                                        name="cv"
                                    >
                                    {({field, form, meta}) => 
                                      <input
                                        {...field}
                                        type="file"
                                        accept=".pdf,.docx,.doc,.jpg,.jpeg,.png"
                                        onChange={(event) => {formikProps.handleChange(event)}}
                                      />
                                    }
                                    </Field>
                                    <FormErrorMessage className="font-sofitex ml-1">{formikProps.errors.cv}</FormErrorMessage>
                                </FormControl>
                              </GridItem>
                            </Grid>
                            <Grid templateColumns="repeat(2, 1fr)" mb={3} mt={8}>
                              <GridItem colSpan={2}>
                                <FormControl isInvalid={formikProps.errors.rgpd && formikProps.touched.rgpd}>
                                    <Checkbox
                                      className="font-sofitex"
                                      colorScheme="sofitex"
                                      name="rgpd"
                                      display={'flex'}
                                      alignItems={'top'}
                                      defaultChecked={formikProps.values.rgpd}
                                      isChecked={formikProps.values.rgpd}
                                      value={formikProps.values.rgpd}
                                      onChange={formikProps.handleChange('rgpd')}
                                    >
                                      <Box className="font-sofitex" as="span" color={'white'} fontSize="sm"><TranslationContainer translationKey="rgpdText" /></Box>&nbsp;<a className="sofitex-link font-sofitex" style={{ color: "#03b403"}} target="_blank" rel="noreferrer" href="https://www.sofitex.fr/FR/politique-de-confidentialite.html"><nobr><TranslationContainer translationKey="knowMore2" /></nobr></a>  
                                    </Checkbox>
                                    <FormErrorMessage className="font-style ml-1">{formikProps.errors.rgpd}</FormErrorMessage>
                                </FormControl>
                              </GridItem>
                            </Grid>
                            <Flex justifyContent="center" width="100%s">
                              <Button mt={6} mx="auto" leftIcon={<RiMailSendLine size={'1.1em'} />} bg={'white'} color={'#03b403'} width={{base: "100%", sm:"auto"}} _hover={{backgroundColor: '#1d1d1d', color: "white"}} isLoading={candidatureLoading} type="submit"><TranslationContainer translationKey="applyBtn" /></Button>                
                            </Flex>
                          </Form>                       
                        )}
                      </Formik>
                    </Box>
                  </GridItem>
                </Grid>
              </Box>
            </Box>
          </Box>
          <Footer />
        </main>
      </>
    )
  }

}