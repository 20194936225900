import { Box, Flex, Button, Heading, Grid, Image, Text, GridItem, Collapse } from '@chakra-ui/react';
import Header from '../components/Header';
import { Masonry } from "masonic";
import { BsArrowRight } from "react-icons/bs";
import { Fade } from 'react-awesome-reveal';
import { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import TranslationContainer from '../languages/Translation/TranslationContainer';


export default function Home() {

  const [images, setImages] = useState([])

  const [collapse1, setCollapse1] = useState(false)
  const [collapse2, setCollapse2] = useState(false)
  const [collapse3, setCollapse3] = useState(false)
  const [collapse4, setCollapse4] = useState(false)
  const [collapse5, setCollapse5] = useState(false)
  const [collapse6, setCollapse6] = useState(false)

  const Card = ({ data: { year, text, src, height, align } }) => (
    // <Fade direction='up' duration={1500} triggerOnce={true}>
      <Box className='story-card' position={'relative'}>
        <Image className='story-img' objectFit={'cover'} loading='lazy' src={src} minHeight={height} />
        <Flex p={10} textAlign={'center'} align={align} justify={'center'} position={'absolute'} top={0} height={'100%'} width={'100%'} color={'white'} bg={'rgba(0, 0, 0, 0.6)'}>
          <Box>
            <Heading>{year}</Heading>
            <Text fontSize={{base: 'lg', md: 'xl'}} mt={5}>{text}</Text>
          </Box>
        </Flex>
      </Box>
    // </Fade>
  );

  useEffect(() => {

    window.scrollTo(0,0);

    setImages(
      [{src: '/images/m_picoux.png', height: '350px', align: 'flex-end', year: '1972 - 1976', text: <TranslationContainer translationKey="masonicText1" />},
      {src: '/images/20230526_075818.jpg', height: '350px', align: 'flex-end', year: '1976 - 1998', text: <TranslationContainer translationKey="masonicText2" />},
      {src: '/images/luxembourgville.png', height: '350px', align: 'center', year: '1998', text: <TranslationContainer translationKey="masonicText3" />},
      {src: '/images/delemont.png', height: '350px', align: 'flex-end', year: '2000', text: <TranslationContainer translationKey="masonicText4" />},
      {src: '/images/bg_transman.jpg', height: '350px', align: 'center', year: '2000', text: <TranslationContainer translationKey="masonicText5" />},
      {src: '/images/e_picoux.png', height: '350px', align: 'flex-end', year: '2000', text: <TranslationContainer translationKey="masonicText6" />},
      {src: '/images/tertiaire bureau 3.jpg', height: '350px', align: 'center', year: '2002', text: <TranslationContainer translationKey="masonicText7" />},
      {src: '/images/bg_sofitex_group.png', height: '350px', align: 'flex-start', year: '2002', text: <TranslationContainer translationKey="masonicText8" />},
      {src: '/images/bg_mulhouse_experts.jpg', height: '350px', align: 'flex-end', year: '2003-2005', text: <TranslationContainer translationKey="masonicText9" />},
      {src: '/images/locaux-maxiplan.jpg', height: '350px', align: 'center', year: '2009', text: <TranslationContainer translationKey="masonicText10" />},
      {src: '/images/img-process.jpg', height: '350px', align: 'flex-end', year: '2009', text: <TranslationContainer translationKey="masonicText11" />},
      {src: '/images/Sofitex-Yasmina.png', height: '350px', align: 'center', year: '2015', text: <TranslationContainer translationKey="masonicText12" />},
      {src: '/images/bg_delemont.jpg', height: '350px', align: 'center', year: '2016', text: <TranslationContainer translationKey="masonicText13" />},
      {src: '/images/bg_paxsajob.png', height: '350px', align: 'flex-end', year: '2021', text: <TranslationContainer translationKey="masonicText14" />},
      {src: '/images/bg_libramont.jpg', height: '350px', align: 'flex-start', year: '2022', text: <TranslationContainer translationKey="masonicText15" />}]
    )}, [])

  return (
    <>
      {/* <Head>
        <title>Create Next App</title>
        <meta name="description" content="Generated by create next app" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head> */}
      <Header page='group' />
      <main>
        <Box pb={20}>
          <Flex className='bg-img-dark' px={{base: 5, lg: 20}} py={10} alignItems={'center'} minHeight={{base: 'calc(100vh - 64px)', md: 'calc(100vh - 73px)'}}>
            <Box className='animated-title'>
              <Image src={'/images/sofitexgroup_blanc.png'} alt='logo sofitex' width={{base: '300px', lg: '550px'}} />
              <Heading mt={2} fontSize={{base: '4xl', lg: '7xl'}} fontWeight={'semibold'} color={'white'} fontStyle={'italic'}><TranslationContainer translationKey="title1" /> <nobr><TranslationContainer translationKey="title2" /></nobr></Heading>
            </Box>
          </Flex>
          <Box my={{base: 10, lg: 20}} px={4}>
            <Grid templateColumns={'repeat(2, 1fr)'} columnGap={10} width={{base:"100%", lg: "1024px", xl: "1250px"}} mx={'auto'}>
              <GridItem display={{base: 'none', lg: 'block'}} colSpan={{lg: 1}}>
                <Image width={'100%'} alt={''} src={'/images/sofitex_group_image.jpg'} />
              </GridItem>
              <GridItem colSpan={{base: 2, lg: 1}}>
                <Heading className='title' fontSize={{base: '4xl', lg: '5xl'}} color={'#03b403'}><TranslationContainer translationKey="title3" /></Heading>
                <Text fontSize={'xl'} mt={10}><TranslationContainer translationKey="text1" /></Text>
                <Text fontSize={'xl'} my={5}><TranslationContainer translationKey="text2" /></Text>
                <Text fontSize={'xl'}><TranslationContainer translationKey="text3" /></Text>
              </GridItem>
            </Grid>
          </Box>
          <Box px={4} py={{base: 10, lg: 20}} bg={'gray.50'}>
            <Box mx={'auto'} width={{base:"100%", lg: "1024px", xl: "1250px"}}>
              <Flex mb={{base: 10, lg: 20}} columnGap={2}>
                <Box mt={{base: '0.6rem', lg: '1.2rem'}} borderLeft={'10px solid #03b403'} height={{base: '1.6rem', lg: '2.6rem'}}></Box>
                <Heading fontSize={{base: '4xl', lg: '6xl'}}><Box as={'span'} fontWeight={'normal'} p={0} m={0}><TranslationContainer translationKey="our" /></Box> <TranslationContainer translationKey="leaders" /></Heading>
              </Flex>
              <Fade direction='up' duration={1500} triggerOnce>
                <Grid templateColumns={{base: 'repeat(4, 1fr)', lg: 'repeat(3, 1fr)'}} columnGap={{base: 10, xl: 20}} rowGap={10}>
                  <GridItem className='card-animation' colSpan={{base: 4, sm: 2, lg: 1}}>
                    <Image mx={'auto'} alt={''} src={'/images/m_picoux_img.jpg'} loading={'lazy'} rounded={'full'} width={'200px'} />
                    <Box mt={4} px={2} py={5} height={{sm: '160px', lg: '160px', xl: '140px'}} bg={'white'} rounded={'lg'} shadow={'sm'}>
                      <Text textAlign={'center'} fontSize={'2xl'} fontWeight={'bold'}>Maurice Picoux</Text>
                      <Text mt={1} textAlign={'center'} fontSize={'md'} fontWeight={'semibold'} color={'gray.600'}>
                      <TranslationContainer translationKey="delegatedAdmin" /></Text>
                    </Box>
                  </GridItem>
                  <GridItem className='card-animation' colSpan={{base: 4, sm: 2, lg: 1}}>
                    <Image mx={'auto'} alt={''} src={'/images/e_picoux_img.jpg'} loading={'lazy'} rounded={'full'} width={'200px'} />
                    <Box mt={4} px={2} py={5} height={{sm: '160px', lg: '160px', xl: '140px'}} bg={'white'} rounded={'lg'} shadow={'sm'}>
                      <Text textAlign={'center'} fontSize={'2xl'} fontWeight={'bold'}>Eric Picoux</Text>
                      <Text mt={1} textAlign={'center'} fontSize={'md'} fontWeight={'semibold'} color={'gray.600'}><TranslationContainer translationKey="admin" /><br/><TranslationContainer translationKey="dirCo" /></Text>
                    </Box>
                  </GridItem>
                  <GridItem className='card-animation'colSpan={{base: 4, sm: 2, lg: 1}} colStart={{sm: 2}}>
                    <Image mx={'auto'} alt={''} src={'/images/jlindecker_img.jpg'} loading={'lazy'} rounded={'full'} width={'200px'} />
                    <Box mt={4} px={2} py={5} height={{sm: '160px', lg: '160px', xl: '140px'}} bg={'white'} rounded={'lg'} shadow={'sm'}>
                      <Text textAlign={'center'} fontSize={'2xl'} fontWeight={'bold'}>Jacques Lindecker</Text>
                      <Text mt={1} textAlign={'center'} fontSize={'md'} fontWeight={'semibold'} color={'gray.600'}><TranslationContainer translationKey="admin" /><br/><TranslationContainer translationKey="dirAdmin" /></Text>
                    </Box>
                  </GridItem>
                </Grid>
              </Fade>
            </Box>
          </Box>
          <Box py={{base: 10, lg: 20}}>
            <Box width={{base:"100%", lg: "1024px", xl: "1250px"}} mx={'auto'}>
              <Flex mb={{base: 10, lg: 20}} columnGap={2} px={{base: 4, lg: 0}}>
                <Box mt={{base: '0.6rem', lg: '1.2rem'}} borderLeft={'10px solid #03b403'} height={{base: '1.6rem', lg: '2.6rem'}}></Box>
                <Heading fontSize={{base: '4xl', lg: '6xl'}}><Box as={'span'} fontWeight={'normal'}><TranslationContainer translationKey="ourSingle" /></Box> <TranslationContainer translationKey="history" /></Heading>
              </Flex>
              <Masonry
                // Provides the data for our grid items
                items={images}
                // Adds 8px of space between the grid cells
                columnGutter={0}
                // Sets the minimum column width to 172px
                columnWidth={400}
                // Pre-renders 5 windows worth of content
                overscanBy={15}
                // This is the grid item component
                render={Card}
              />
            </Box>
          </Box>
          <Box width={{base:"100%", lg: "1024px", xl: "1250px"}} py={{base: 10, lg: 20}} px={4} mx={'auto'}>
            <Flex columnGap={2}>
              <Box mt={{base: '0.6em', lg: '1.2em'}} borderLeft={'10px solid #03b403'} height={{base: '1.6em', lg: '2.6em'}}></Box>
              <Heading fontSize={{base: '4xl', lg: '6xl'}}><Box as={'span'} fontWeight={'normal'}><TranslationContainer translationKey="ourPillars" /></Box> <TranslationContainer translationKey="csr" /></Heading>
            </Flex>
            <Text mt={5} mb={{base: 10, lg: 20}} fontSize={'xl'}><TranslationContainer translationKey="csrIntro" /></Text>
            <Grid templateColumns="repeat(12, 1fr)" columnGap={{md: 5, xl: 10}} rowGap={{base: 10, lg: 20}} textAlign={'justify'}>
              <GridItem colSpan={{base: 12, md: 6, lg: 4}} fontSize={'xl'}>
                <Flex as={'button'} minHeight={'100px'} width={'100%'} alignItems={'center'} justifyContent={'center'} rounded={'md'} bg={'#03b403'} color={'white'} _hover={{backgroundColor: 'white', color: 'black', border: '2px solid #03b403'}} onClick={() => setCollapse1(!collapse1)}>
                  <Text px={2} py={3} fontSize="2xl" fontWeight="bold" textAlign={'center'}><TranslationContainer translationKey="cardTitle1" /></Text>
                </Flex>
                <Collapse in={collapse1} animateOpacity>
                  <Box pt={8} px={{base: 4, md: 6}} minHeight={{md: '400px'}}>
                    <Text>
                      <TranslationContainer translationKey="cardText1" />
                      {/* Ainsi, nous nous assurons d'adapter constamment nos mesures de prévention et de sécurité pour maintenir un environnement de travail sûr et sain pour tous nos collaborateurs. */}
                    </Text>
                    <Text mt={2}>
                      <TranslationContainer translationKey="cardText2" />
                    </Text>
                  </Box>
                </Collapse>
              </GridItem>
              <GridItem colSpan={{base: 12, md: 6, lg: 4}} fontSize={'xl'}>
                <Flex as={'button'} minHeight={'100px'} width={'100%'} alignItems={'center'} justifyContent={'center'} rounded={'md'} bg={'#03b403'} color={'white'} _hover={{backgroundColor: 'white', color: 'black', border: '2px solid #03b403'}} onClick={() => setCollapse2(!collapse2)}>
                  <Text px={2} py={3} fontSize="2xl" fontWeight="bold" textAlign={'center'}><TranslationContainer translationKey="cardTitle2" /></Text>
                </Flex>
                <Collapse in={collapse2} animateOpacity>
                  <Box pt={8} px={{base: 4, md: 6}} minHeight={{md: '400px'}}>
                    <Text>
                      <TranslationContainer translationKey="cardText3" />
                      {/* Nous accompagnons et conseillons nos intérimaires en tenant compte du marché de l'emploi et de la rigueur législative, afin de garantir des pratiques justes et équitables. */}
                    </Text>
                    <Text mt={2}>
                      <TranslationContainer translationKey="cardText4" />
                    </Text>
                  </Box>
                </Collapse>
              </GridItem>
              <GridItem colSpan={{base: 12, md: 6, lg: 4}} fontSize={'xl'}>
                <Flex as={'button'} minHeight={'100px'} width={'100%'} alignItems={'center'} justifyContent={'center'} rounded={'md'} bg={'#03b403'} color={'white'} _hover={{backgroundColor: 'white', color: 'black', border: '2px solid #03b403'}} onClick={() => setCollapse3(!collapse3)}>
                  <Text px={2} py={3} fontSize="2xl" fontWeight="bold" textAlign={'center'}><TranslationContainer translationKey="cardTitle3" /></Text>
                </Flex>
                <Collapse in={collapse3} animateOpacity>
                  <Box pt={8} px={{base: 4, md: 6}} minHeight={{md: '400px'}}>
                    <Text>
                      <TranslationContainer translationKey="cardText5" />
                      {/* Ces initiatives se complètent et renforcent l'engagement global de Sofitex en faveur de la durabilité et de la protection de l'environnement. */}
                    </Text>
                    <Text mt={2}>
                      <TranslationContainer translationKey="cardText6" />
                    </Text>
                  </Box>
                </Collapse>
              </GridItem>
              <GridItem colSpan={{base: 12, md: 6, lg: 4}} fontSize={'xl'}>
                <Flex as={'button'} minHeight={'100px'} width={'100%'} alignItems={'center'} justifyContent={'center'} rounded={'md'} bg={'#03b403'} color={'white'} _hover={{backgroundColor: 'white', color: 'black', border: '2px solid #03b403'}} onClick={() => setCollapse4(!collapse4)}>
                  <Text px={2} py={3} fontSize="2xl" fontWeight="bold" textAlign={'center'}><TranslationContainer translationKey="cardTitle4" /></Text>
                </Flex>
                <Collapse in={collapse4} animateOpacity>
                  <Box pt={8} px={{base: 4, md: 6}} minHeight={{md: '468px'}}>
                    <Text>
                      <TranslationContainer translationKey="cardText7" />
                      {/* Ces approches permettent de fournir aux travailleurs les compétences nécessaires pour répondre aux exigences du marché du travail, renforçant ainsi leur employabilité et favorisant leur évolution professionnelle. */}
                    </Text>
                    <Text mt={2}>
                      <TranslationContainer translationKey="cardText8" />
                    </Text>
                  </Box>
                </Collapse>
              </GridItem>
              <GridItem colSpan={{base: 12, md: 6, lg: 4}} fontSize={'xl'}>
                <Flex as={'button'} minHeight={'100px'} width={'100%'} alignItems={'center'} justifyContent={'center'} rounded={'md'} bg={'#03b403'} color={'white'} _hover={{backgroundColor: 'white', color: 'black', border: '2px solid #03b403'}} onClick={() => setCollapse5(!collapse5)}>
                  <Text px={2} py={3} fontSize="2xl" fontWeight="bold" textAlign={'center'}><TranslationContainer translationKey="cardTitle5" /></Text>
                </Flex>
                <Collapse in={collapse5} animateOpacity>
                  <Box pt={8} px={{base: 4, md: 6}} minHeight={{md: '468px'}}>
                    <Text>
                      <TranslationContainer translationKey="cardText9" />
                    </Text>
                    <Text mt={2}>
                      <TranslationContainer translationKey="cardText10" />
                    </Text>
                  </Box>
                </Collapse>
              </GridItem>
              <GridItem colSpan={{base: 12, md: 6, lg: 4}} fontSize={'xl'}>
                <Flex as={'button'} minHeight={'100px'} width={'100%'} alignItems={'center'} justifyContent={'center'} rounded={'md'} bg={'#03b403'} color={'white'} _hover={{backgroundColor: 'white', color: 'black', border: '2px solid #03b403'}} onClick={() => setCollapse6(!collapse6)}>
                  <Text px={2} py={3} fontSize="2xl" fontWeight="bold" textAlign={'center'}><TranslationContainer translationKey="cardTitle6" /></Text>
                </Flex>
                <Collapse in={collapse6} animateOpacity>
                  <Box pt={8} px={{base: 4, md: 6}} minHeight={{md: '468px'}}>
                    <Text>
                      <TranslationContainer translationKey="cardText11" />
                    </Text>
                    <Text mt={2}>
                      <TranslationContainer translationKey="cardText12" />
                    </Text>
                    <Text mt={2}>
                      <TranslationContainer translationKey="cardText13" />
                    </Text>
                  </Box>
                </Collapse>
              </GridItem>
            </Grid>
            <Flex justify={'center'}>
              <Button mt={{base: 10, md: 20}} py={7} variant={'outline'} borderColor={'black'} width={{base: '100%', sm: 'unset'}} fontSize={{base: 'sm', md: 'lg'}} size={'lg'} rounded={'full'} _hover={{backgroundColor: 'black', color: 'white'}} rightIcon={<BsArrowRight />} onClick={() => window.open('https://rse.sofitex.fr')}><TranslationContainer translationKey="csrBtn" /></Button>
            </Flex>
          </Box>
        </Box>
        <Footer />
      </main>
    </>
  )
}