import { Box, Button, Collapse, Flex, Text, Image } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {GiHamburgerMenu} from 'react-icons/gi'
import {AiOutlineClose} from 'react-icons/ai'
import { Link } from "react-router-dom";
import TranslationContainer from "../languages/Translation/TranslationContainer";

export default function Header(props) {

    const [show, setShow] = useState(false);

    const [page, setPage] = useState('')

    const handleToggle = () => setShow(!show);

    useEffect(() => {
        setPage(props.page)
    }, [])

    return (
        <Box position={'sticky'} top={0} left={0} zIndex={9999}>
            <Flex px={{base: 5, lg: 10}} py={3} bg={'white'} alignItems={'center'} width={'100%'} shadow={'md'}>
                <Box mr={'auto'}>
                    <Link to={'/'}>
                        <Image src={'/images/Sofitexgroup_green-black.png'} width={{base: 150, lg: 200}} />
                    </Link>
                </Box>
                <Flex columnGap={10} display={{base: 'none', lg: 'flex'}}>
                    <Link to={'/'}>
                        <Box as={'button'} fontWeight={'semibold'} py={1} className={page === 'group' ? 'nav-btn-selected' : 'nav-btn'} rounded={'none'}><TranslationContainer translationKey="menuGroup" /></Box>
                    </Link>
                    <Link to={'/nos_marques'}>
                        <Box as={'button'} fontWeight={'semibold'} py={1} className={page === 'brands' ? 'nav-btn-selected' : 'nav-btn'} rounded={'none'}><TranslationContainer translationKey="menuBrands" /></Box>
                    </Link>
                    <Link to={'/nos_solutions'}>
                        <Box as={'button'} fontWeight={'semibold'} py={1} className={page === 'solutions' ? 'nav-btn-selected' : 'nav-btn'} rounded={'none'}><TranslationContainer translationKey="menuSolutions" /></Box>
                    </Link>
                    {/* <Link to={'/nous_recrutons'}>
                        <Box as={'button'} fontWeight={'semibold'} py={1} className={page === 'offers' ? 'nav-btn-selected' : 'nav-btn'} rounded={'none'}><TranslationContainer translationKey="menuHiring" /></Box>
                    </Link> */}
                </Flex>
                <Box>
                    <Button display={{base: 'block', lg: 'none'}} size="md" onClick={() => handleToggle()}>
                        {show ? <AiOutlineClose /> : <GiHamburgerMenu />}
                    </Button>
                </Box>
            </Flex>
            <Box position={'fixed'} top={{base: '64px', lg: '73px'}} display={{base: 'block', lg: 'none'}} width={'100%'} bg={'white'} shadow={'md'} borderTop={'1px solid #e2e8f0'}>
                <Collapse in={show}>
                    <Box>
                        {/* <Link className="nav-link" href={'/'} onClick={() => handleToggle()}>
                            <Text p={5} borderBottom={'1px solid #e2e8f0'} color={page === 'accueil' ? '#03b403' : 'black'} fontWeight={'bold'} textAlign={'center'}>Accueil</Text>
                        </Link> */}
                        <Link className="nav-link" to={'/'} onClick={() => handleToggle()}>
                            <Text p={4} color={page === 'group' ? '#03b403' : 'black'} fontWeight={'bold'} textAlign={'center'}><TranslationContainer translationKey="menuGroup" /></Text>
                        </Link>
                        <Link className="nav-link" to={'/nos_marques'} onClick={() => handleToggle()}>
                            <Text p={4} color={page === 'brands' ? '#03b403' : 'black'} fontWeight={'bold'} textAlign={'center'}><TranslationContainer translationKey="menuBrands" /></Text>
                        </Link>
                        <Link className="nav-link" to={'/nos_solutions'} onClick={() => handleToggle()}>
                            <Text p={4} color={page === 'solutions' ? '#03b403' : 'black'} fontWeight={'bold'} textAlign={'center'}><TranslationContainer translationKey="menuSolutions" /></Text>
                        </Link>
                        {/* <Link className="nav-link" to={'/nous_recrutons'} onClick={() => handleToggle()}>
                            <Text p={5} borderBottom={'1px solid #e2e8f0'} color={page === 'offers' ? '#03b403' : 'black'} fontWeight={'bold'} textAlign={'center'}><TranslationContainer translationKey="menuHiring" /></Text>
                        </Link> */}
                    </Box>
                </Collapse>
            </Box>
        </Box>
    )
}