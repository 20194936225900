import { Box, Heading, Text } from '@chakra-ui/react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import TranslationContainer from '../languages/Translation/TranslationContainer';
import { useEffect } from 'react';

export default function PrivacyPolicy() {

  useEffect(() => {
    window.scrollTo(0,0);
  }, [])
  
    return (
      <>
        <Header page='' />
        <main>
          <Box width={{base:"100%", lg: "1024px"}} mx={'auto'} px={{base: 4, md: 8}} pt={{base: 10, md: 20}} pb={20}>
            <Heading><TranslationContainer translationKey="p1" /></Heading>
            <Text pt={5} fontSize={'lg'}><TranslationContainer translationKey="p2" /></Text>
            <Text pt={5} fontSize={'lg'}><TranslationContainer translationKey="p3" /></Text>
            <Text pt={5} fontSize={'lg'}><TranslationContainer translationKey="p4" /></Text>
            <Text pt={5} fontSize={'lg'}><TranslationContainer translationKey="p5" /></Text>
            <Text pt={5} fontSize={'lg'}><TranslationContainer translationKey="p6" /></Text>
            <Text pt={5} fontSize={'lg'}><TranslationContainer translationKey="p7" /></Text>
            <Text pt={5} fontSize={'lg'}><TranslationContainer translationKey="p8" /></Text>
            <Text pt={5} fontSize={'lg'}><TranslationContainer translationKey="p9" /></Text>
            <Text pt={5} fontSize={'lg'}><TranslationContainer translationKey="p10" /></Text>
            <Text pt={5} fontSize={'lg'}><TranslationContainer translationKey="p11" /></Text>
            <Text pt={5} fontSize={'lg'}><TranslationContainer translationKey="p12" /></Text>
            <Text pt={5} fontSize={'lg'}><TranslationContainer translationKey="p13" /> <a className='link' href='mailto: rgpd@sofitex-group.com'>rgpd@sofitex-group.com</a> <TranslationContainer translationKey="p14" /></Text>
          </Box>
          <Footer />
        </main>
      </>
    )
  
}