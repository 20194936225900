export const TRANSLATIONS = {
  en: {
    ourAgencies: "OUR AGENCIES IN",
    ourAgencies2: "OUR AGENCIES IN",
    france: "FRANCE",
    luxembourg: "LUXEMBOURG",
    allemagne: "GERMANY",
    suisse: "SWITZERLAND",
    belgique: "BELGIUM",
    textSofitexFrance: "Sofitex France is a network of agencies supplying temporary workers and recruitment and human resources management services, which operates in a number of industries.",
    textSofitexExperts: "Sofitex Experts is a network of agencies supplying temporary workers specialized in engineering and industry, IT and telecoms and new technology.",
    textMaxiplan: "Maxiplan is an agency supplying temporary workers specialized in architecture, design, construction, engineering, industry, graphic arts and technical assistance.",
    textProcess: "Process Technology is a company that offers industry services for the quality control of parts or sub-assemblies, in its own site or on customers’ sites, and technical assistance services.",
    textSofitexLuxembourg: "Sofitex Luxembourg is a network of agencies supplying temporary workers and recruitment and human resources management services, which operates in a number of industries.",
    textSofitexTalent: "Sofitex Talent Luxembourg is an agency specialized in delegation and the recruitment of experts in the areas of finance, office work and IT.",
    textTransman: "Transman Consulting is a recruitment firm specialized in finance, accounting and management, banking and insurance, sales and marketing, human resources, payroll, purchasing and logistics.",
    textSofitexDE: "Sofitex Germany is a network of agencies supplying temporary workers and recruitment and human resources management services, which operates in a number of industries.",
    textSofitexCH: "Sofitex Switzerland is agency supplying temporary workers and recruitment and human resources management services, which operates in a number of industries.",
    textAxellia: "Axellia is a company supplying temporary workers and recruitment and human resources management services to industry in the Jura region, construction and services.",
    textPaxsajob: "Paxsajob is a company specialized in placement in the areas of Bienne and Tramelan.",
    textSofitexBE: "Sofitex Belgium is agency supplying temporary workers and recruitment and human resources management services.",
    generalist: "Generalist",
    logisticsAndTransport: "Logistics and transport",
    btpAndIndustry: "Construction and industry",
    industry: "Industry",
    logistics: "Logistics",
    events: "Events",
    tertiaire: "Services",
    technicien: "Technician",
    ingenieur: "Engineer",
    cadres: "Managerial staff",
    architectes: "Architects",
    ingenieurs: "Engineers",
    projeteurs: "Designers",
    dessinateurs: "Draftsmen",
    graphistes: "Graphic designers",
    controle: "Inspectors",
    tri: "Sorting",
    retouche: "Rework",
    assistanceTechnique: "Technical assistance",
    formation: "Training",
    btp: "Construction and public works",
    services: "Services",
    finance: "Finance",
    office: "Office work",
    it: "IT",
    banque: "Banking",
    assurances: "Insurance",
    chantier: "Work sites",
    electricite: "Electricity",
    international: "Cross-border establishment",
    errorMessage: "An error has occurred",
    hiringTitle: "Join the big Sofitex family",
    hiringText1: "We are always looking for new talents.",
    hiringText2: "View our job offers and apply right away!",
    allCountries: "All countries",
    allLocations: "All locations",
    allJobs: "All jobs",
    allTypes: "All types",
    searchBtn: "Search",
    cancelSearch: "Cancel search",
    noOffers: "No offer for now",
    country: "Country",
    location: "Location",
    job: "Job",
    contractType: "Type of contract",
    poste: "Position",
    type: "Type",
    cdi: "Permanent",
    cdd: "Fixed term",
    alternance: "Study and work",
    stage: "Internship",
    interim: "Temporary work",
    errorMandatoryName: "Surname required",
    errorMandatoryFirstName: "Forename required",
    errorInvalidEmail: "E-mail address not valid",
    errorMandatoryEmail: "E-mail address required",
    errorMandatoryPhone: "Telephone number required",
    errorMandatoryCV: "CV required",
    errorMandatoryRGPD: "You must accept the standard terms and conditions of use to send your application",
    jan: "January",
    feb: "February",
    mar: "March",
    apr: "April",
    may: "May",
    jun: "June",
    jul: "July",
    aug: "August",
    sep: "September",
    oct: "October",
    nov: "November",
    dec: "December",
    reference: "Reference",
    localisation: "Location",
    domaine: "Area of business",
    published: "published on",
    companyDescription: "Description of company",
    jobDescription: "Description of offer",
    profileDescription: "Profile sought",
    applicationTitle: "Apply",
    name: "Surname",
    firstName: "Forename",
    email: "E-mail address",
    phoneNumber: "Telephone number",
    cv: "CV",
    rgpdText: "I hereby accept the standard terms and conditions of use of Sofitex and the use of my data for the strict purposes of my job application.",
    knowMore2: "more information",
    applyBtn: "Send application",
    masonicText1: "Discovery by Maurice Picoux of his calling for temporary work.",
    masonicText2: "Founding and development of 40 agencies with his first temporary work group Inter Alsace in France",
    masonicText3: "Purchase of Sofitex Luxembourg and appointment of Jacques Lindecker as Administrative and Finance Director",
    masonicText4: "Founding of Sofitex Germany in Saarbrücken and Sofitex Switzerland in Delémont",
    masonicText5: "Founding of Transman Luxembourg, recruitment firm specialized in finance",
    masonicText6: "Arrival of Eric Picoux in the first Sofitex agency in Esch-sur-Alzette, Luxembourg",
    masonicText7: "Takeover of ICA in Obernai and founding of the Sofitex-ICA group in France",
    masonicText8: "Founding of Sofitex Group SA in Luxembourg",
    masonicText9: "Founding of the first recruitment and temporary work firm Sofitex Expert and opening of 15 Sofitex agencies in France",
    masonicText10: "Purchase of Maxiplan, temporary work company specialized in the placement of architects and design engineers",
    masonicText11: "Founding of Process Technology, company specialized in technical assistance, IT, digital, engineering and inspection, sorting and rework",
    masonicText12: "Opening of the Sofitex Experts agency in Strasbourg",
    masonicText13: "Founding of Axellia, temporary and fixed placement company in Boncourt and Delémont, Switzerland",
    masonicText14: "Founding of PaxsaJob, temporary and fixed placement company in Bienne and Tramelan, Switzerland",
    masonicText15: "Founding of Sofitex Belgium in Libramont",
    title1: "The entrepreneurial spirit",
    title2: "and family values",
    our: "OUR",
    ourSingle: "OUR",
    leaders: "LEADERS",
    creator: "Founder",
    delegatedAdmin: "Delegated Director",
    admin: "Director",
    dirCo: "Operational and Sales Department",
    dirAdmin: "Administrative, Finance and Legal Department",
    title3: "A Passion for Excellence",
    text1: "With over 35 years’ experience in the fields of recruitment, temporary work and services for companies, Sofitex Group has earned the trust of many clients in France, Switzerland, Germany, Luxembourg and Belgium.",
    text2: "Our people combine generosity, humanism, thoroughness and imagination to achieve excellence, and are driven by the spirit of enterprise, dedication and responsiveness.",
    text3: "The dynamic and success of the Sofitex concept are based on commitments to the threefold satisfaction of clients, temporary workers and permanent employees.",
    history: "HISTORY",
    ourPillars: "OUR PILLARS",
    csr: "CSR",
    csrIntro: "Sofitex is convinced that business performance relies on employment, cultural, social and environmental performance. In addition to the application of the human rights and ethics requirements of our trade, we are guided by a CSR approach in everything we do.",
    cardTitle1: "Well-being at the workplace",
    cardText1: "We are committed to assessing and preventing workplace risks and promoting occupational health and safety. To that end, we monitor innovations continually.",
    cardText2: "That vigilance enables us to be at the leading edge of the technology, practices and methods that improve working conditions and reduce occupational risks.",
    cardTitle2: "Non discrimination",
    cardText3: "Our commitment to occupational equality and non discrimination is reflected in the training of our employees.",
    cardText4: "As part of that approach, we work in close collaboration with all the parties involved in employment, both public and institutional, to create and inclusive environment where equal opportunities are available to all.",
    cardTitle3: "Control of our environmental impacts",
    cardText5: "We use digital solutions in order to reduce the use of paper and thus help protect the environment.",
    cardText6: "Further, by preferring responsible local purchasing, we support the local economy by reducing our carbon footprint relating to the transport of goods.",
    cardTitle4: "Job security",
    cardText7: "To effectively support upskilling, we promote independence, versatility and dedication in our employees and temporary workers by giving them training opportunities (collective operational preparation for employment, individualized career development contracts etc.).",
    cardText8: "In that way, we promote the development of their careers.",
    cardTitle5: "Responsible communication",
    cardText9: "Firm in our belief that the human factor is at the heart of exchange, we personalize contacts as we know it is essential to bring people around entrepreneurial values.",
    cardText10: "Similarly, we encourage initiative, independence and creativity to make our employees and temporary workers more committed and involved in their interactions.",
    cardTitle6: "Partnerships and patronage",
    cardText11: "We transmit our values through sponsoring, patronage and support to local voluntary organizations.",
    cardText12: "By joining community initiatives, Sofitex rises to its social responsibilities and renews its commitment to collective well-being.",
    cardText13: "That is why we foster personalized interaction centered on the individual, while reinforcing the social fabric and well-being of the local community.",
    csrBtn: "More about our CSR policy",
    mysofitexText1: "is our application to serve our temporary workers and applicants.",
    mysofitexText2: "24/7 access to your agency. Make your requests for payments and leave online, download your documents, sign your documents electronically.",
    knowMore: "More information",
    sofitexOnlineText1: "is a temporary work management platform that is specially designed for our clients.",
    sofitexOnlineText2: "Complete all your formalities online, on your own and in no time! View and download your documents and sign your contracts directly on the platform. Access your HR data and statistics and follow their trends.",
    sofitexInsideText1: "Companies needing more than 40 temporary workers can rely on a tailored service from Sofitex",
    sofitexInsideText2: "The specialist recruiters from our agencies will be with you to help you with recruitment and administrative and employee management.",
    sofitexJobNightText1: "Personalized recruitment evenings for our clients.",
    sofitexJobNightText2: "is a concept in the job dating format held in the evening, only for hard-to-find profiles.",
    sofitexJobNightText3: "The aim is to arouse the interest of future employees in an original way.",
    menuGroup: "Our Group",
    menuBrands: "Our Brands",
    menuSolutions: "Our Solutions",
    menuHiring: "Join us",
    partners: "Partners",
    sponsoring: "Sponsoring",
    agencies: "Find our agencies in",
    franceFooter: "France",
    luxembourgFooter: "Luxembourg",
    allemagneFooter: "Germany",
    suisseFooter: "Switzerland",
    belgiqueFooter: "Belgium",
    legalInformations: "Legal information",
    privacyPolicy: "Privacy policy",
    m1: "Legal information",
    m2: "About the site",
    m3: "You are currently connected to the Sofitex Group website. All data and information on the",
    m4: "website are made available to the public by Sofitex Group.",
    m5: "Legal status: Limited company",
    m6: "Publication Director: Maurice PICOUX",
    m7: "Hosting",
    m8: "Credits",
    m9: "Design and technical implementation:",
    m10: "Website and services general conditions of use",
    m11: "The use of the",
    m12: "site implies full and complete acknowledgement of the general conditions described below. These conditions of use may be modified or supplemented at any time, users of the",
    m13: "site are therefore invited to check them regularly.",
    m14: "This site is normally accessible to users at any time. An interruption due to technical maintenance may however be decided by Sofitex Group, who will then endeavor to communicate to users before the dates and times of the intervention.",
    m15: "The",
    m16: "website is updated regularly by Sofitex Group. In the same way, the legal mentions can be modified at any time: nevertheless, they apply to the user who is invited to check them as often as possible in order to be aware of them.",
    m17: "Description of services provided",
    m18: "The purpose of the",
    m19: "website is to provide information regarding the company's overall activities.",
    m20: "Sofitex Group strives to provide information that is as precise as possible on the",
    m21: "site. However, the company cannot be held responsible for any omissions, inaccuracies and deficiencies in the update, whether by the company or by third parties who provide this information.",
    m22: "All the information provided on the",
    m23: "site is given as an indication and is subject to change. In addition, the information on the",
    m24: "site is not exhaustive. It is provided subject to modification since being posted on line.",
    m25: "Contractual limitations on technical data",
    m26: "The site uses JavaScript technology.",
    m27: "The website can not be held responsible for material damage related to the use of the site. In addition, the user of the site agrees to access the site using current equipment, not containing any viruses and with an up-to-date last generation browser.",
    m28: "Intellectual property and counterfeits",
    m29: "Sofitex Group owns the intellectual property rights or holds the rights to use all the items available on the site, including text, images, graphics, logos, icons, sounds, software.",
    m30: "Any reproduction, representation, modification, publication, adaptation of all or part of the elements of the site, whatever the means or the process used, is prohibited without the prior written authorization of: Sofitex Group.",
    m31: "Any unauthorized use of the site or any of the elements it contains will be considered as constituting an infringement and prosecuted in accordance with the provisions in this area (in particular the amended law of April 18, 2001 on copyright, related rights and databases).",
    m32: "Limitations of liability",
    m33: "Sofitex Group can not be held liable for direct or indirect damage to the user's equipment when accessing the",
    m34: "site, and resulting from the use of equipment that does not meet the specifications indicated in point 4, either the appearance of a bug or an incompatibility.",
    m35: "Nor can Sofitex Group be held liable for indirect damages (such as for example a loss of market or loss of opportunity) resulting from the use of the site",
    m36: "Interactive spaces (possibility to ask questions in the contact area) are available to users. Sofitex Group reserves the right to delete, without prior notice, any content posted in this space that would violate the applicable legislation in France, especially the provisions on data protection. If applicable, Sofitex Group also reserves the right to question the user's civil and / or criminal liability, particularly in the event of a racist, abusive, defamatory or pornographic message, regardless of the medium used (text, photography…).",
    m37: "Applicable law and jurisdiction",
    m38: "Any dispute in connection with the use of the",
    m39: "site is subject to Luxembourg law. The courts of Luxembourg have exclusive jurisdiction.",
    p1: "Information On The Processing Of Personal Data Of Candidates, Temporary Employees And Permanent Employees",
    p2: "The company responsible for the processing of your data is SOFITEX GROUP, registered with the Trade and Companies Register of LUXEMBOURG, having its registered office at 11 Place Sts Pierre et Paul L-2334 LUXEMBOURG, and all of its subsidiaries.",
    p3: "Your personal data is collected by SOFITEX GROUP and all of its subsidiaries at the moment of your application during the recruitment process, as well as at the end of your interim assignment, in particular through oral or written exchanges, forms, questionnaires, cookies and from third parties (see list of subcontractors).",
    p4: "The purposes of said processing are in particular, but not limited to:",
    p5: "The assessment of your capability of holding the job offered or your professional abilities; the respect, on the part of SOFITEX GROUP and all of its subsidiaries, of their legal, regulatory and collective obligations. The aim of the latter, in particular, is to manage applications and staff, draw up the work contract, the pay slips, the resulting welfare declarations and the corresponding payments, have and provide the information necessary for your selection in case of placement or of an assignment offer in order to communicate with you.",
    p6: "During your application, all of the data required on the registration form are, unless otherwise indicated, necessary for the confirmation of your application and the assessment of your professional abilities to hold the job offered.",
    p7: "For the aforementioned purposes, SOFITEX GROUP and all of its subsidiaries may process identification data, economic and financial data, health data, your social security number and personal and professional information, as well as your B3 extract of your criminal record for a specific and regulated position (e.g. driver, accountant, nurse...).",
    p8: "Your data is kept for a maximum period of 2 years starting from the last contact as far as data concerning candidates during the recruitment phase is concerned. Data concerning employees is kept for a maximum period of 10 years starting from the last pay slip, at the issue of which said data will be destroyed or anonymized.",
    p9: "Your data may be transferred, for the aforementioned purposes, to third parties or subcontractors (e.g. software houses...) within the European Union.",
    p10: "SOFITEX GROUP and all of its subsidiaries have adopted the necessary safety, confidentiality and integrity measures for the transfer of your personal data.",
    p11: "Additional guarantee measures, e.g. contractual clauses, must be adopted when personal data is transferred to non-EU countries and to countries recognized by the European Union as countries with laws that guarantee an appropriate protection of personal data, in particular as regards the processing of data of non-European citizens (expats).",
    p12: "At SOFITEX GROUP and all of its subsidiaries, you may exercise your right to oppose the use of your personal data, provided that it is justified by a legitimate reason. At SOFITEX GROUP and all of its subsidiaries, you may also exercise your rights to access, rectify and cancel your personal data and the right to define, modify and withdraw, at any given time, the directives relating to the preservation, deletion and communication of your data after your death.",
    p13: "All these rights may be exercised by writing to the address above or to this e-mail",
    p14: "and proving your identity (surname, first name, address, identification number, copy of a document proving your identity).",
    brandsIntro: "Sofitex Group currently employs 130 permanent staff, daily hires over 3000 temporary workers for 1500 client companies, and generates a turnover of approximately 140 million euros.",
  },

  fr: {
    //Modal Langues
    languageChoiceTitle: "Choisir une langue",

    //Brands
    ourAgencies: "NOS AGENCES EN",
    ourAgencies2: "NOS AGENCES AU",
    france: "FRANCE",
    luxembourg: "LUXEMBOURG",
    allemagne: "ALLEMAGNE",
    suisse: "SUISSE",
    belgique: "BELGIQUE",
    textSofitexFrance: "Sofitex France est un réseau d'agences d'intérim, travail temporaire, de recrutement et de gestion des ressources humaines présent dans de nombreux secteurs d'activités.",
    textSofitexExperts: "Sofitex Experts est un réseau d’agences emploi spécialisé dans l'ingénierie et Industrie, l'informatique et Télécoms et les nouvelles technologies.",
    textMaxiplan: "Maxiplan est une agence d'intérim spécialisée dans l'architecture, les bureaux d'études, le bâtiment, l'ingénierie, l'industrie, les arts graphiques et l'assistanat technique.",
    textProcess: "Process Technology est une entreprise qui propose aux industriels des prestations de contrôle Qualité de pièces ou de sous-ensembles sur sites ou sur sites clients, ainsi que des services d'Assistance technique.",
    textSofitexLuxembourg: "Sofitex Luxembourg est un réseau d'agences d'intérim, travail temporaire, de recrutement et de gestion des ressources humaines présent dans de nombreux secteurs d'activités.",
    textSofitexTalent: "Sofitex Talent Luxembourg est une agence spécialisée dans la délégation et le recrutement d'experts dans les secteurs Finance, Office, IT.",
    textTransman: "Transman Consulting est un cabinet de recrutement spécialisé Finance, Comptabilité et Gestion, Banque et Assurance, Commercial et Marketing, Ressources humaines et Paies, Achat et Logistique.",
    textSofitexDE: "Sofitex Allemagne est un réseau d'agences d'intérim de recrutement et de ressources humaines présent dans de nombreux secteurs d'activités.",
    textSofitexCH: "Sofitex Suisse est une agence intérim de travail temporaire, de recrutement et de gestion des ressources humaines présent dans de nombreux secteurs d'activités.",
    textAxellia: "Axellia est une entreprise spécialisée dans la travail temporaire, le recrutement et la gestion des ressources humaines dans les secteurs de l'industrie de l'arc jurassien, du bâtiment et du tertiaire.",
    textPaxsajob: "Paxsajob est une entreprise spécialisée dans le placement, dans les secteurs de Bienne et Tramelan.",
    textSofitexBE: "Sofitex Belgique est une agence d'intérim de travail temporaire, de recrutement et de gestion des ressources humaines.",
    generalist: "Généraliste",
    logisticsAndTransport: "Logistique et Transport",
    btpAndIndustry: "BTP et Industrie",
    industry: "Industrie",
    logistics: "Logistique",
    events: "Évènementiel",
    tertiaire: "Tertiaire",
    technicien: "Technicien",
    ingenieur: "Ingénieur",
    cadres: "Cadres",
    architectes: "Architectes",
    ingenieurs: "Ingénieurs",
    projeteurs: "Projeteurs",
    dessinateurs: "Dessinateurs",
    graphistes: "Graphistes",
    controle: "Contrôle",
    tri: "Tri",
    retouche: "Retouche",
    assistanceTechnique: "Assistance Technique",
    formation: "Formation",
    btp: "BTP",
    services: "Services",
    finance: "Finance",
    office: "Office",
    it: "IT",
    banque: "Banque",
    assurances: "Assurances",
    chantier: "Chantier",
    electricite: "Électricité",
    international: "Implantation transfrontalière",
    brandsIntro: "Sofitex Group compte à ce jour 130 collaborateurs permanents, emploie quotidiennement plus de 3000 intérimaires auprès de 1500 entreprises clientes et réalise un chiffre d'affaires d'environ 140 millions d'euros.",

    // Hiring
    errorMessage: "Une erreur s'est produite",
    hiringTitle: "Rejoignez la grande famille Sofitex",
    hiringText1: "Nous sommes toujours à la recherche de nouveaux talents.",
    hiringText2: "Consultez nos offres d'emploi et postulez dès aujourd'hui !",
    allCountries: "Tous les pays",
    allLocations: "Toutes les localisations",
    allJobs: "Tous les métiers",
    allTypes: "Tous les types",
    searchBtn: "Rechercher",
    cancelSearch: "Annuler la recherche",
    noOffers: "Aucune annonce pour le moment",
    country: "Pays",
    location: "Localisation",
    job: "Métier",
    contractType: "Type de contrat",
    poste: "Poste",
    type: "Type",
    cdi: "CDI",
    cdd: "CDD",
    alternance: "Alternance",
    stage: "Stage",
    interim: "Intérim",
    errorMandatoryName: "Nom obligatoire",
    errorMandatoryFirstName: "Prénom obligatoire",
    errorInvalidEmail: "Adresse email invalide",
    errorMandatoryEmail: "Adresse email obligatoire",
    errorMandatoryPhone: "Numéro de téléphone obligatoire",
    errorMandatoryCV: "CV obligatoire",
    errorMandatoryRGPD: "Vous devez accepter les conditions générales d'utilisation pour envoyer votre candidature",
    jan: "janvier",
    feb: "février",
    mar: "mars",
    apr: "avril",
    may: "mai",
    jun: "juin",
    jul: "juillet",
    aug: "août",
    sep: "septembre",
    oct: "octobre",
    nov: "novembre",
    dec: "décembre",
    reference: "Référence",
    localisation: "Localisation",
    domaine: "Domaine d'activité",
    published: "publié le",
    companyDescription: "Description de l'entreprise",
    jobDescription: "Description de l'offre",
    profileDescription: "Profil recherché",
    applicationTitle: "Je dépose ma candidature",
    name: "Nom",
    firstName: "Prénom",
    email: "Adresse email",
    phoneNumber: "Numéro de téléphone",
    cv: "CV",
    rgpdText: "J'accepte les conditions générales d'utilisation de Sofitex ainsi que l'exploitation de mes données dans le strict cadre de ma recherche d'emploi.",
    knowMore2: "en savoir plus",
    applyBtn: "Envoyer ma candidature",

    //Home
    masonicText1: "Découverte d'une vocation par Maurice Picoux : le Travail Temporaire.",
    masonicText2: "Création et développement de 40 agences avec son 1er groupe de Travail Temporaire Inter Alsace en France",
    masonicText3: "Achat de Sofitex Luxembourg et arrivée de Jacques Lindecker à la direction administrative et financière",
    masonicText4: "Création de Sofitex Allemagne à Sarrebruck et Sofitex Suisse à Delémont",
    masonicText5: "Création de Transman Luxembourg, cabinet de recrutement spécialisé dans la finance",
    masonicText6: "Arrivée de Eric Picoux dans la 1ère agence Sofitex à Esch-sur-Alzette au Luxembourg",
    masonicText7: "Rachat de ICA à Obernai et création de Groupe Sofitex-ICA en France",
    masonicText8: "Création de Sofitex Group S.A au Luxembourg",
    masonicText9: "Création du 1er Cabinet de Recrutement et d'Interim Sofitex Experts et ouverture de 15 agences Sofitex en France",
    masonicText10: "Rachat de Maxiplan, société de Travail Temporaire spécialisée dans le placement d'architectes et d'ingénieurs en bureau d'étude",
    masonicText11: "Création de Process Technology, société spécialisée dans l’assistance technique, informatique, digitale, engineering et contrôle, tri et retouche",
    masonicText12: "Ouverture de l’agence Sofitex Experts à Strasbourg",
    masonicText13: "Création de Axellia, société de placement temporaire et fixe en Suisse à Boncourt et Delémont",
    masonicText14: "Création de PaxsaJob, société de placement temporaire et fixe en Suisse à Bienne et Tramelan",
    masonicText15: "Création de Sofitex Belgique à Libramont",
    title1: "L'esprit entrepreneurial",
    title2: "et familial",
    our: "NOS",
    ourSingle: "NOTRE",
    leaders: "DIRIGEANTS",
    creator: "Fondateur",
    delegatedAdmin: "Administrateur délégué",
    admin: "Administrateur",
    dirCo: "Direction Opérationnelle et Commerciale",
    dirAdmin: "Direction administrative, financière et juridique",
    title3: "La Passion de l'Excellence",
    text1: "Fort d'une expérience de plus de 35 ans dans les domaines du recrutement, de l'intérim et des services aux entreprises, Sofitex Group a su gagner la confiance de nombreux clients en France, Suisse, Allemagne, Luxembourg et Belgique.",
    text2: "Animé par des collaborateurs passionnés conjuguant générosité, humanisme, rigueur et imagination pour atteindre l’excellence de leur métier, ils agissent en véritables chefs d’entreprise avec beaucoup d’implication et de disponibilité.",
    text3: "Toute la dynamique et le succès du concept Sofitex sont fondés sur des engagements liés à la triple satisfaction des clients, des intérimaires et des collaborateurs permanents.",
    history: "HISTOIRE",
    ourPillars: 'NOS PILIERS',
    csr: "RSE",
    csrIntro: "SOFITEX est convaincu que la performance économique repose sur la performance sociale, culturelle, sociétale et environnementale. Au-delà de l'application des exigences relatives aux Droits de l'Homme et à la Déontologie dans notre métier, nous incluons une démarche RSE forte dans nos actions au quotidien.",
    cardTitle1: "Bien-être au travail",
    cardText1: "Nous nous engageons à évaluer et prévenir les risques professionnels, ainsi qu'à garantir la santé et la sécurité au travail. Dans cet objectif, nous restons en veille permanente sur les innovations.",
    cardText2: "Cette vigilance nous permet de rester à la pointe des avancées technologiques, des pratiques et des méthodes visant à améliorer les conditions de travail et à réduire les risques professionnels.",
    cardTitle2: "Lutte contre la discrimination",
    cardText3: "Notre engagement envers l'égalité professionnelle et la lutte contre les discriminations se concrétise par la formation de nos collaborateurs.",
    cardText4: "Dans cette démarche, nous travaillons en étroite collaboration avec l'ensemble des acteurs de l'emploi, qu'ils soient privés ou institutionnels, afin de créer un environnement inclusif et favoriser des opportunités égales pour tous.",
    cardTitle3: "Maîtrise de nos impacts environnementaux",
    cardText5: "Nous optons pour des solutions numériques dans le but de réduire l'utilisation de papier et contribuons ainsi à la préservation de l'environnement.",
    cardText6: "De plus, en privilégiant des achats responsables et locaux, nous soutenons l'économie locale tout en réduisant notre empreinte carbone liée au transport des marchandises.",
    cardTitle4: "Sécurisation de l'emploi",
    cardText7: "Pour accompagner efficacement le développement des compétences, nous stimulons l'autonomie, la polyvalence et l'implication de nos collaborateurs et intérimaires grâce à des opportunités de formation (Préparation Opérationnelle à l'Emploi Collective, Contrat de Développement Professionnel Individualisé, etc.).",
    cardText8: "Nous favorisons ainsi leur évolution professionnelle.",
    cardTitle5: "Communication responsable",
    cardText9: "Nous plaçons l'humain au cœur de l'échange et personnalisons les contacts, car nous estimons qu'il est essentiel de fédérer autour des valeurs intrapreneuriales.",
    cardText10: "De la même manière, nous encourageons l'esprit d'initiative, l'autonomie et la créativité afin que nos collaborateurs et intérimaires se sentent davantage engagés et investis dans leurs interactions.",
    cardTitle6: "Partenariat et Mécénat",
    cardText11: "Nous transmettons nos valeurs à travers le sponsoring, le mécénat et les soutiens auprès d'associations locales.",
    cardText12: "En s'associant à des initiatives communautaires, Sofitex renforce sa responsabilité sociale et renouvelle son engagement envers la collectivité.",
    cardText13: "Nous favorisons ainsi les interactions personnalisées et centrées sur les individus, tout en renforçant le tissu social et le bien-être de la communauté locale.",
    csrBtn: "En savoir plus sur notre politique RSE",

    //Solutions
    mysofitexText1: "est notre application au service de nos intérimaires et candidats.",
    mysofitexText2: "Accéder à votre agence 24/24h, 7/7j. Effectuez vos demandes d'acomptes et de congés directement en ligne, téléchargez vos documents, signez vos contrats électroniquement.",
    knowMore: "En savoir plus",
    sofitexOnlineText1: "est une plateforme de gestion de l'interim spécialement conçue pour nos clients.",
    sofitexOnlineText2: "Effectuez toutes vos démarches en ligne, en toute autonomie et rapidité ! Consultez et téléchargez vos documents administratifs et signez vos contrats directement sur la plateforme. Accédez à vos statistiques, vos données RH et suivez leurs évolutions.",
    sofitexInsideText1: "Pour les entreprises ayant des besoins supérieurs à 40 collaborateurs intérimaires, Sofitex vous propose son service sur-mesure",
    sofitexInsideText2: "Les recruteurs spécialisés de nos agences sont chez vous et s'occupent des recrutements et de la gestion administrative et sociale.",
    sofitexJobNightText1: "Soirées de recrutement personnalisées pour nos clients.",
    sofitexJobNightText2: "est un concept sous format JobDating en soirée, réservé aux profils en pénurie.",
    sofitexJobNightText3: "L'objectif est de susciter l'intérêt de futurs collaborateurs de manière originale.",

    //Header
    menuGroup: "Notre groupe",
    menuBrands: "Nos marques",
    menuSolutions: "Nos solutions",
    menuHiring: "Nous rejoindre",

    //Footer
    partners: "Partenaires",
    sponsoring: "Sponsoring",
    agencies: "Retrouvez nos agences en",
    franceFooter: "France",
    luxembourgFooter: "Luxembourg",
    allemagneFooter: "Allemagne",
    suisseFooter: "Suisse",
    belgiqueFooter: "Belgique",
    legalInformations: "Mentions légales",
    privacyPolicy: "Politique de confidentialité",

    //Mentions légales
    m1: "Mentions légales",
    m2: "A propos du site",
    m3: "Vous êtes actuellement connecté au site Internet de Sofitex Group. L’ensemble des données et les informations présentes sur le site Internet",
    m4: "sont mises à disposition du public par Sofitex Group.",
    m5: "Forme juridique : Société anonyme",
    m6: "Directeur de la publication : Maurice PICOUX",
    m7: "Hébergement",
    m8: "Crédits",
    m9: "Conception et mise en œuvre technique :",
    m10: "Conditions générales d’utilisation du site et des services proposés",
    m11: "L’utilisation du site",
    m12: "implique l’acceptation pleine et entière des conditions générales d’utilisation ci-après décrites. Ces conditions d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment, les utilisateurs du site",
    m13: "sont donc invités à les consulter de manière régulière.",
    m14: "Ce site est normalement accessible à tout moment aux utilisateurs. Une interruption pour raison de maintenance technique peut être toutefois décidée par Sofitex Group, qui s’efforcera alors de communiquer préalablement aux utilisateurs les dates et heures de l’intervention.",
    m15: "Le site",
    m16: "est mis à jour régulièrement par Sofitex Group. De la même façon, les mentions légales peuvent être modifiées à tout moment : elles s’imposent néanmoins à l’utilisateur qui est invité à s’y référer le plus souvent possible afin d’en prendre connaissance.",
    m17: "Description des services fournis",
    m18: "Le site",
    m19: "a pour objet de fournir une information concernant l’ensemble des activités de la société.",
    m20: "Sofitex Group s’efforce de fournir sur le site",
    m21: "des informations aussi précises que possible. Toutefois, il ne pourra être tenue responsable des omissions, des inexactitudes et des carences dans la mise à jour, qu’elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations.",
    m22: "Tous les informations indiquées sur le site",
    m23: "sont données à titre indicatif, et sont susceptibles d’évoluer. Par ailleurs, les renseignements figurant sur le site",
    m24: "ne sont pas exhaustifs. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne.",
    m25: "Limitations contractuelles sur les données techniques",
    m26: "Le site utilise la technologie JavaScript.",
    m27: "Le site Internet ne pourra être tenu responsable de dommages matériels liés à l’utilisation du site. De plus, l’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour",
    m28: "Propriété intellectuelle et contrefaçons",
    m29: "Sofitex Group est propriétaire des droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments accessibles sur le site, notamment les textes, images, graphismes, logo, icônes, sons, logiciels.",
    m30: "Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de : Sofitex Group.",
    m31: "Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.",
    m32: "Limitations de responsabilité",
    m33: "Sofitex Group ne pourra être tenue responsable des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès au site",
    m34: ", et résultant soit de l’utilisation d’un matériel ne répondant pas aux spécifications indiquées au point 4, soit de l’apparition d’un bug ou d’une incompatibilité.",
    m35: "Sofitex Group ne pourra également être tenue responsable des dommages indirects (tels par exemple qu’une perte de marché ou perte d’une chance) consécutifs à l’utilisation du site",
    m36: "Des espaces interactifs (possibilité de poser des questions dans l’espace contact) sont à la disposition des utilisateurs. Sofitex Group se réserve le droit de supprimer, sans mise en demeure préalable, tout contenu déposé dans cet espace qui contreviendrait à la législation applicable au Luxembourg, en particulier aux dispositions relatives à la protection des données. Le cas échéant, Sofitex Group se réserve également la possibilité de mettre en cause la responsabilité civile et/ou pénale de l’utilisateur, notamment en cas de message à caractère raciste, injurieux, diffamant, ou pornographique, quel que soit le support utilisé (texte, photographie…).",
    m37: "Droit applicable et attribution de juridiction",
    m38: "Tout litige en relation avec l’utilisation du site",
    m39: "est soumis au droit luxembourgeois. Il est fait attribution exclusive de juridiction aux tribunaux compétents de Luxembourg.",
    p1: "Politique de confidentialité des données personnelles",
    p2: "Le responsable du traitement de vos données est la Société SOFITEX GROUP immatriculée au Registre du Commerce et des Sociétés de LUXEMBOURG dont le siège social est situé 11 Place Sts Pierre et Paul L-2334 LUXEMBOURG et l'ensemble de ses établissements.",
    p3: "Vos données à caractère personnel sont recueillies par la Société SOFITEX GROUP et l'ensemble de ses établissements lors de votre candidature durant le processus de recrutement et, en fin de mission par plusieurs biais et notamment lors d'échanges oraux et / ou écrits, de formulaires, de questionnaires, de cookies et auprès de tiers.",
    p4: "Les finalités poursuivies par ce traitement sont notamment et non exhaustives :",
    p5: "L'appréciation de votre capacité à occuper l'emploi proposé ou vos aptitudes professionnelles et le respect par la Société SOFITEX GROUP et l'ensemble de ses établissements de ses obligations légales, conventionnelles et règlementaires, afin notamment de gérer les candidatures et le personnel, établir le contrat de mission, les bulletins de salaire, les déclarations sociales qui en découlent et les paiements correspondants, disposer et fournir des renseignements nécessaires à votre sélection en vue d'un placement ou d'une proposition de mission pour échanger avec vous.",
    p6: "Lors de votre candidature, l'ensemble des données requises sur le formulaire d'inscription sont, sauf mention contraire, nécessaires à la validation de votre candidature et à l'appréciation de vos aptitudes professionnelles pour occuper l'emploi proposé.",
    p7: "Aux fins précitées, la Société SOFITEX GROUP et l'ensemble de ses établissements est susceptible de traiter des données d'identification, des données d'ordre économique et financier, des données de santé, votre numéro de sécurité sociale et ses informations personnelles et professionnelles ainsi que votre extrait B3 de votre casier judiciaire pour un poste spécifique et règlementé (exemple : conducteur/conductrice, comptable, infirmier (e)..)",
    p8: "Les données vous concernant sont conservées pendant une durée de 2 ans maximum à compter du dernier contact s'agissant des données de candidats pendant la phase de recrutement et de 10 ans maximum à compter de la dernière paie pour les données des salariés, à l'issue de laquelle lesdites données seront détruites ou anonymisées.",
    p9: "Vos données sont susceptibles d'être transférées, pour les finalités précitées, à des tiers ou sous-traitants (exemple : CCSS, éditeur de logiciel..) situé dans l'Union Européenne.",
    p10: "La Société SOFITEX GROUP et l'ensemble de ses établissements a pris les mesures de sécurité, de confidentialité et d'intégrité nécessaires pour le transfert de vos données personnelles.",
    p11: "Des mesures de garantie supplémentaires devront être prises telles que des clauses contractuelles types lorsque les données personnelles sont transférées vers des pays situés hors de l'Union Européenne et des pays reconnus par l'Union Européenne comme ayant des lois garantissant une protection adéquate des données personnelles notamment dans le cas de traitement de données de citoyens non européens (expatriés).",
    p12: "Vous pouvez exercer auprès de la Société SOFITEX GROUP et l'ensemble de ses établissements votre droit d'opposition à l'utilisation de vos données à caractère personnel, sous réserve de justifier d'un motif légitime. Vous pouvez également exercer auprès de la Société SOFITEX GROUP et l'ensemble de ses établissements vos droits d'accès, de rectification et de suppression des données à caractère personnel vous concernant et votre droit de définir, modifier et révoquer à tout moment des directives relatives à la conservation, à l'effacement et à la communication de vos données après votre décès.",
    p13: "Tous ces droits peuvent être exercés en écrivant à l'adresse postale ci-dessus ou à l'adresse électronique",
    p14: "en justifiant de votre identité (nom, prénom, adresse, numéro d'identification, copie d'un justificatif d'identité).",
  },

  de: {
    ourAgencies: "UNSERE AGENTUREN IN",
    ourAgencies2: "UNSERE AGENTUREN IN ",
    france: "FRANKREICH",
    luxembourg: "LUXEMBURG",
    allemagne: "DEUTSCHLAND",
    suisse: "DER SCHWEIZ",
    belgique: "BELGIEN",
    textSofitexFrance: "Sofitex France ist ein Netz von Agenturen für Arbeitnehmerüberlassung, Zeitarbeit, Personalbeschaffung und Personalmanagement.",
    textSofitexExperts: "Sofitex Experts ist ein Netz von Stellenvermittlungsagenturen mit Spezialisierung auf Ingenieurwesen und Industrie, Informationstechnologie und Telekommunikation sowie neue Technologien.",
    textMaxiplan: "Maxiplan ist ein Netz von Agenturen für Arbeitnehmerüberlassung mit Spezialisierung auf Architektur, Planungsbüros, Baugewerbe, Ingenieurwesen, Industrie, Grafikdesign und technische Assistenz.",
    textProcess: "Process Technology ist ein Unternehmen, das Industrieunternehmen Qualitätskontrollen von Teilen oder Baugruppen vor Ort oder bei Kunden sowie technische Unterstützung anbietet.",
    textSofitexLuxembourg: "Sofitex Luxemburg ist ein Netz von Agenturen für Arbeitnehmerüberlassung, Zeitarbeit, Personalbeschaffung und Personalmanagement, die für viele unterschiedliche Branchen tätig sind.",
    textSofitexTalent: "Sofitex Talent Luxemburg ist eine Agentur, die sich auf die Vermittlung und Anwerbung von Fachleuten in den Bereichen Finanzen, Office und IT spezialisiert hat.",
    textTransman: "Transman Consulting ist eine Personalvermittlungsagentur mit Spezialisierung auf Finanzen, Rechnungswesen und Betriebswirtschaft, Banken und Versicherungen, Vertrieb und Marketing, Personal- und Lohnwesen, Einkauf und Logistik.",
    textSofitexDE: "Sofitex Deutschland ist ein Netz von Agenturen für Zeitarbeit, Personalbeschaffung und Personalmanagement, die für viele unterschiedliche Branchen tätig sind.",
    textSofitexCH: "Sofitex Schweiz ist ein Netz von Agenturen für Zeitarbeit, Personalbeschaffung und Personalmanagement, die für viele unterschiedliche Branchen tätig sind.",
    textAxellia: "Axellia ist ein Unternehmen, das sich auf Zeitarbeit, Personalbeschaffung und Personalmanagement für Industrieunternehmern verschiedener Branchen im französischen Juragebiet, das Baugewerbe und den Dienstleistungssektor spezialisiert hat.",
    textPaxsajob: "Paxsajob ist ein Unternehmen, das sich auf Personalvermittlung in Biel und Tramelan und dem umgebenden Einzugsgebiet spezialisiert hat.",
    textSofitexBE: "Sofitex Belgien ist eine Agentur für Zeitarbeit, Personalbeschaffung und Personalmanagement.",
    generalist: "Alle Branchen",
    logisticsAndTransport: "Logistik und Transport",
    btpAndIndustry: "Baugewerbe und Industrie",
    industry: "Industrie",
    logistics: "Logistik",
    events: "Veranstaltungsbranche",
    tertiaire: "Dienstleistungsbranche",
    technicien: "Techniker",
    ingenieur: "Ingenieur",
    cadres: "Führungskräfte",
    architectes: "Architekten",
    ingenieurs: "Ingenieure",
    projeteurs: "Planer",
    dessinateurs: "Zeichner",
    graphistes: "Grafiker",
    controle: "Kontrolle",
    tri: "Sortieren",
    retouche: "Nachbearbeitung",
    assistanceTechnique: "Technische Assistenz",
    formation: "Ausbildung",
    btp: "Baugewerbe",
    services: "Dienstleistungen",
    finance: "Finanzwesen",
    office: "Office",
    it: "IT",
    banque: "Banken",
    assurances: "Versicherungen",
    chantier: "Baustelle",
    electricite: "Elektrik",
    international: "Grenzüberschreitende Niederlassung",
    errorMessage: "Ein Fehler ist aufgetreten.",
    hiringTitle: "Werden Sie Teil der großen Sofitex-Familie",
    hiringText1: "Wir sind ständig auf der Suche nach neuen Talenten.",
    hiringText2: "Schauen Sie sich unsere Stellenangebote an und bewerben Sie sich noch heute!",
    allCountries: "Alle Länder",
    allLocations: "Alle Orte",
    allJobs: "Alle Tätigkeiten",
    allTypes: "Alle Arten",
    searchBtn: "Suchen",
    cancelSearch: "Suche abbrechen",
    noOffers: "Momentan leider keine Angebote",
    country: "Land",
    location: "Ort",
    job: "Tätigkeit",
    contractType: "Vertragsart",
    poste: "Stelle",
    type: "Art",
    cdi: "unbefristet",
    cdd: "befristet",
    alternance: "Duale Ausbildung",
    stage: "Praktikum",
    interim: "Zeitarbeit",
    errorMandatoryName: "Name ist Pflichtangabe",
    errorMandatoryFirstName: "Vorname ist Pflichtangabe",
    errorInvalidEmail: "Ungültige E-Mail-Adresse",
    errorMandatoryEmail: "E-Mail-Adresse ist Pflichtangabe",
    errorMandatoryPhone: "Telefonnummer ist Pflichtangabe",
    errorMandatoryCV: "Bitte fügen Sie Ihren Lebenslauf bei",
    errorMandatoryRGPD: "Bitte akzeptieren Sie die allgemeinen Nutzungsbedingungen, bevor Sie Ihre Bewerbung abschicken",
    jan: "Januar",
    feb: "Februar",
    mar: "März",
    apr: "April",
    may: "Mai",
    jun: "Juni",
    jul: "Juli",
    aug: "August",
    sep: "September",
    oct: "Oktober",
    nov: "November",
    dec: "Dezember",
    reference: "Nummer",
    localisation: "Ort",
    domaine: "Branche",
    published: "veröffentlicht am",
    companyDescription: "Beschreibung des Unternehmens",
    jobDescription: "Beschreibung des Angebots",
    profileDescription: "Gesuchtes Profil",
    applicationTitle: "Bewerbung einreichen",
    name: "Name",
    firstName: "Vorname",
    email: "E-Mail-Adresse",
    phoneNumber: "Telefonnummer",
    cv: "Lebenslauf",
    rgpdText: "Ich akzeptiere die allgemeinen Nutzungsbedingungen von Sofitex sowie die Verwendung meiner Daten ausschließlich im Rahmen meiner Stellensuche.",
    knowMore2: "mehr erfahren",
    applyBtn: "Bewerbung abschicken",
    masonicText1: "Maurice Picoux entdeckt eine Berufung: die Zeitarbeit.",
    masonicText2: "Gründung und Ausbau von 40 Agenturen im Rahmen seiner ersten Unternehmensgruppe für Zeitarbeit „Inter-Alsace“ in Frankreich",
    masonicText3: "Erwerb von Sofitex Luxemburg und Eintritt von Jacques Lindecker in die Verwaltungs- und Finanzleitung",
    masonicText4: "Gründung von Sofitex Deutschland in Saarbrücken und Sofitex Schweiz in Delémont (Delsberg)",
    masonicText5: "Gründung von Transman Luxembourg, einer Personalvermittlungsagentur mit Spezialisierung auf das Finanzwesen",
    masonicText6: "Eintritt von Eric Picoux in die erste Sofitex-Agentur in Esch-sur-Alzette, Luxemburg",
    masonicText7: "Übernahme von ICA in Obernai und Gründung der Gruppe Sofitex-ICA in Frankreich",
    masonicText8: "Gründung der Sofitex Group S.A in Luxemburg",
    masonicText9: "Gründung der ersten Personalvermittlungs- und Zeitarbeitsagentur Sofitex Experts und Eröffnung von 15 Sofitex-Agenturen in Frankreich",
    masonicText10: "Übernahme von Maxiplan, Zeitarbeitsgesellschaft mit Spezialisierung auf die Vermittlung von Architekten und Planungsingenieuren",
    masonicText11: "Gründung der Gesellschaft Process Technology, die auf Assistenz in den Bereichen Technik, Informatik und Digitales, sowie auf Engineering und Kontrolle, Sortierung und Nachbearbeitung spezialisiert ist",
    masonicText12: "Eröffnung der Sofitex-Experts-Agentur in Straßburg",
    masonicText13: "Gründung von Axellia, Gesellschaft für Zeitarbeit und Direktvermittlung in Boncourt und Delémont (Delsberg), Schweiz",
    masonicText14: "Gründung von PaxsaJob, Gesellschaft für Zeitarbeit und Direktvermittlung in Biel und Tramelan, Schweiz",
    masonicText15: "Gründung von Sofitex Belgien in Libramont",
    title1: "Unternehmergeist",
    title2: "und Familiengeist",
    our: "UNSERE",
    ourSingle: "UNSERE",
    leaders: "GESCHÄFTSLEITUNG",
    creator: "Gründer",
    delegatedAdmin: "Delegiertes Mitglied des Verwaltungsrats",
    admin: "Mitglied des Verwaltungsrats",
    dirCo: "Operative und kaufmännische Leitung",
    dirAdmin: "Administrative, finanzielle und rechtliche Leitung",
    title3: "Exzellenz ist unsere Passion",
    text1: "Mit mehr als 35 Jahren Erfahrung in den Bereichen Personalbeschaffung, Zeitarbeit und Dienstleistungen für Unternehmen konnte die Sofitex Group das Vertrauen zahlreicher Kunden in Frankreich, der Schweiz, Deutschland, Luxemburg und Belgien gewinnen",
    text2: "Zu verdanken haben wir das unseren passionierten Mitarbeitern: Mit ihrer Offenheit, ihrer Menschlichkeit, ihrer Gewissenhaftigkeit und ihrem Einfallsreichtum schaffen Sie Exzellentes in ihrem Metier durch unternehmerisches Handeln, großes Engagement und Einsatzbereitschaft.",
    text3: "Diese Dynamik und der Erfolg des Sofitex-Konzepts entsteht durch Commitments, die mit der Zufriedenheit auf drei Seiten verbunden sind: bei den Kunden, den Zeitarbeitskräften und den festen Mitarbeitern.",
    history: "GESCHICHTE",
    ourPillars: 'UNSERE GRUNDPFEILER',
    csr: "CSR",
    csrIntro: "Bei SOFITEX ist man davon überzeugt, dass die wirtschaftliche Leistung auf der Leistung in den Bereichen Kultur, gesellschaftliche Verantwortung und Umwelt beruht. Wir setzen die Anforderungen in Bezug auf Menschenrechte und ethische Grundsätze in unserem Geschäft um. Darüber hinaus orientieren wir uns bei unserem täglichen Handeln in hohem Maße am Prinzip der gesellschaftlichen Unternehmensverantwortung (Corporate Social Responsibility, CSR).",
    cardTitle1: "Wohlbefinden am Arbeitsplatz",
    cardText1: "Wir verpflichten uns, Berufsrisiken zu bewerten und zu vermeiden sowie Gesundheit und Sicherheit am Arbeitsplatz zu gewährleisten. Hierzu verfolgen wir laufend die aktuellen Innovationen.",
    cardText2: "Durch diese Beobachtung bleiben wir stets uns auf dem neuesten Stand der technologischen Fortschritte, der Praktiken und der Methoden zur Verbesserung der Arbeitsbedingungen und zur Verringerung von Arbeitsrisiken.",
    cardTitle2: "Kampf gegen Diskriminierung",
    cardText3: "Unser Engagement für berufliche Gleichstellung und den Kampf gegen Diskriminierung wird setzen wird durch Schulung unserer Mitarbeiter konkret um..",
    cardText4: "Hierbei arbeiten wir eng mit allen privaten und öffentlichen Einrichtungen im Bereich der Beschäftigung zusammen mit dem Ziel, ein Umfeld zu schaffen, das Inklusion ermöglicht, und gleiche Chancen für alle zu fördern.",
    cardTitle3: "Begrenzung der von uns verursachten Umweltauswirkungen",
    cardText5: "Wir wählen digitale Lösungen, um den Papierverbrauch zu reduzieren, und leisten so einen Beitrag zum Umweltschutz.",
    cardText6: "Darüber hinaus bevorzugen wir verantwortungsbewusste, lokale Einkäufe und unterstützen so die örtliche Wirtschaft bei gleichzeitiger Reduktion unseres CO2-Fußabdrucks beim Warentransport.",
    cardTitle4: "Beschäftigungssicherung",
    cardText7: "Um die Entwicklung von Kompetenzen wirksam zu begleiten, fördern wir die Eigenständigkeit, die Vielseitigkeit und die Einbindung unserer Mitarbeiter und Zeitarbeitnehmer durch Fortbildungsmöglichkeiten (gemeinsame betriebliche Beschäftigungsvorbereitung, individueller Vertrag zur beruflichen Entwicklung usw.).",
    cardText8: "Wir fördern damit ihre berufliche Entwicklung.",
    cardTitle5: "Verantwortungsvolle Kommunikation",
    cardText9: "Wir stellen den Menschen in den Mittelpunkt des Dialogs und legen Wert auf persönliche Kontakte, denn wir meinen, dass die gemeinsame Bindung an unternehmensinterne Werte von wesentlicher Bedeutung ist.",
    cardText10: "Ebenso fördern wir Eigeninitiative, Eigenständigkeit und Kreativität, damit sich unsere Mitarbeiter und Zeitarbeitnehmer in ihren gegenseitigen Beziehungen und im Umgang miteinander stärker einbezogen und mitverantwortlich fühlen.",
    cardTitle6: "Partnerschaften und Mäzenatentum",
    cardText11: "Wir vermitteln unsere Werte über Sponsoring, Mäzenatentum und Förderung örtlicher Vereinigungen.",
    cardText12: "Durch die Beteiligung an Gemeinschaftsinitiativen stärkt Sofitex seine soziale Verantwortung und bekräftigt sein Engagement für die Gemeinschaft",
    cardText13: "Auf diese Weise fördern wir die persönlichen Beziehungen, bei denen der Einzelne im Mittelpunkt steht, und stärken gleichzeitig das soziale Gefüge und das Wohlergehen der örtlichen Gemeinschaft.",
    csrBtn: "Erfahren Sie mehr über unsere CSR-Leitlinien",
    mysofitexText1: "ist unsere App, die unseren Zeitarbeitskräften und Bewerbern zur Verfügung steht.",
    mysofitexText2: "Sie haben Zugang zu Ihrer Agentur 24 Stunden am Tag, 7 Tage die Woche. Reichen Sie Ihre Vorschuss- und Urlaubsanträge direkt online ein, laden Sie Ihre Dokumente hoch oder herunter, unterschreiben Sie Ihre Verträge elektronisch",
    knowMore: "Mehr erfahren",
    sofitexOnlineText1: "ist eine Plattform für das Zeitarbeitsmanagement, die wir speziell für unsere Kunden entwickelt haben.",
    sofitexOnlineText2: "Erledigen Sie alle Ihre Vorgänge online, unabhängig und schnell! Rufen Sie Ihre Verwaltungsdokumente ab und laden Sie sie hoch und unterzeichnen Sie Ihre Verträge direkt auf der Plattform. Greifen Sie auf Ihre Statistiken und Personaldaten zu und verfolgen Sie deren Entwicklung.",
    sofitexInsideText1: "Für Unternehmen mit einem Bedarf von mehr als 40 Zeitarbeitskräften bietet Sofitex einen maßgeschneiderten Service an",
    sofitexInsideText2: "Die spezialisierten Personalvermittler in unseren Agenturen sind bei Ihnen vor Ort und kümmern sich um die Einstellungen sowie die Erledigung der administrativen und der sozial- und arbeitsrechtlichen Vorgänge.",
    sofitexJobNightText1: "Gezielt auf unsere Kunden zugeschnittene Personalvermittlungsabende.",
    sofitexJobNightText2: "ist ein Konzept im Format eines abendlichen Job-Datings speziell für solche Profile, bei denen ein Mangel herrscht.",
    sofitexJobNightText3: "Ziel ist es, das Interesse künftiger Mitarbeiter mit einer originelle Methode zu wecken.",
    menuGroup: "Unsere Gruppe",
    menuBrands: "Unsere Marken",
    menuSolutions: "Unsere Lösungen",
    menuHiring: "Kommen Sie zu uns",
    partners: "Partner",
    sponsoring: "Sponsoring",
    agencies: "Finden Sie unsere Agenturen in",
    franceFooter: "Frankreich",
    luxembourgFooter: "Luxemburg",
    allemagneFooter: "Deutschland",
    suisseFooter: "Schweiz",
    belgiqueFooter: "Belgien",
    legalInformations: "Impressum",
    privacyPolicy: "Datenschutzerklärung",
    m1: "Impressum",
    m2: "Über diese Seite",
    m3: "Sie besuchen gerade die Website von Sofitex Group. Alle Angaben und Informationen auf der Seite",
    m4: "werden von Sofitex Group zur Verfügung gestellt.",
    m5: "Rechtsform: Aktiengesellschaft",
    m6: "Für den Inhalt der Seite verantwortlich: Maurice PICOUX",
    m7: "Hosting",
    m8: "Credits",
    m9: "Gestaltung und technische Umsetzung:",
    m10: "Allgemeine Nutzungsbedingungen der Seite und der angebotenen Dienstleistungen",
    m11: "Die Nutzung der Seite",
    m12: "setzt voraus, dass Sie den nachfolgend beschriebenen allgemeinen Nutzungsbedingungen in vollem Umfang zustimmen. Diese können jederzeit geändert oder ergänzt werden. Deshalb werden die Nutzer der Seite",
    m13: "gebeten, sie regelmäßig einzusehen.",
    m14: "Die Nutzer können normalerweise jederzeit auf diese Seite zugreifen. Sofitex Group kann jedoch beschließen, aufgrund einer technischen Wartung eine Unterbrechung herbeizuführen; den Nutzern werden die entsprechenden Daten und Uhrzeiten im Vorfeld mitgeteilt.",
    m15: "Die Seite",
    m16: "wird regelmäßig von Sofitex Group aktualisiert. Ebenso können jederzeit Änderungen im Impressum erfolgen, die für den Nutzer verbindlich sind; dieser wird deshalb gebeten, diese so oft wie möglich einzusehen und zur Kenntnis zu nehmen.",
    m17: "Beschreibung der angebotenen Dienstleistungen",
    m18: "Gegenstand der Seite",
    m19: "ist es, über sämtliche Tätigkeiten des Unternehmens zu informieren.",
    m20: "Sofitex Group ist bemüht, auf der Seite",
    m21: "möglichst präzise Angaben zu machen, kann jedoch nicht haftbar gemacht werden für Auslassungen, Unrichtigkeiten und fehlende Aktualisierungen. Dabei ist es unerheblich, ob diese auf eigenem Verschulden oder auf dem Dritter beruhen, die solche Angaben bereitstellen.",
    m22: "Alle auf der Seite",
    m23: "bereitgestellten Angaben sind rein informativ und können sich jederzeit ändern. Im Übrigen erheben die Angaben auf der Seite",
    m24: "keinen Anspruch auf Vollständigkeit. Sie werden vorbehaltlich der Änderungen bereitgestellt, die seit ihrer Aktualisierung erfolgt sind.",
    m25: "Vertragliche Beschränkungen der technischen Date",
    m26: "Die Seite verwendet JavaScript.",
    m27: "Die Internetseite haftet nicht für materielle Schäden in Zusammenhang mit ihrer Nutzung. Zudem verpflichtet sich der Nutzer der Seite, diese ausschließlich über aktuelles und virenfreien Material und über einen Browser der neuesten Version zu besuchen.",
    m28: "Geistiges Eigentum und Fälschungen",
    m29: "Sofitex Group ist Inhaber des Urheberrechts oder der Nutzungsrechte an allen über die Seite zugänglichen Elementen, insbesondere Texten, Bildern, Grafiken, Logos, Symbolen, Tondateien und Software.",
    m30: "Eine vollständige oder teilweise Nachbildung, Darstellung, Änderung, Veröffentlichung oder Anpassung der Elemente der Seite ist unabhängig von dem verwendeten Verfahren oder Weg untersagt, sofern im Vorfeld keine schriftliche Genehmigung von Sofitex Group eingeholt wurde.",
    m31: "Eine nicht autorisierte Verwendung der Seite oder einiger Elemente davon wird als Fälschung erachtet und strafrechtlich verfolgt gemäß den gesetzlichen Bestimmungen in diesem Bereich (insbesondere dem geänderten Gesetz vom 18. April 2001 über Urheberrecht, verwandte Schutzrechte und Datenbanken).",
    m32: "Haftungsbeschränkung",
    m33: "Sofitex Group haftet nicht für direkte oder indirekte Schäden am Material des Nutzers aufgrund von dessen Besuch der Seite",
    m34: ", wenn diese entweder auf dem Einsatz von Material beruhen, welches nicht den unter 4. genannten Spezifikationen entspricht, oder auf einem Programmierfehler bzw. einer Inkompatibilität.",
    m35: "Des Weiteren haftet Sofitex Group nicht für indirekte Schäden (beispielsweise entgangene Bestellungen oder Chancen) infolge der Nutzung der Seite",
    m36: "Den Nutzern stehen interaktive Bereiche (Möglichkeit, Fragen in einem Kontaktbereich zu stellen) zur Verfügung. Sofitex Group behält sich das Recht vor, ohne vorherige Ankündigung Inhalte in diesem Bereich zu entfernen, die der in Frankreich geltenden Rechtssprechung widersprechen, insbesondere in Bezug auf die Datenschutzbestimmungen. Gegebenenfalls behält sich Sofitex Group ebenfalls die Möglichkeit vor, die zivil- und/oder strafrechtliche Haftung des Nutzers in Anspruch zu nehmen, insbesondere bei Nachrichten mit rassistischem, beleidigendem, diffamierendem oder pornographischem Inhalt unabhängig von deren Form (Text, Foto…).",
    m37: "Anwendbares Recht und Gerichtsstand",
    m38: "Streitigkeiten in Zusammenhang mit der Nutzung der Seite",
    m39: "unterliegen dem französischen Recht. Für die Rechtssprechung sind ausschließlich die Gerichte in Luxemburg zuständig.",
    p1: "Datenschutzrichtlinie zum Umfgang mit personenbezogenen Daten",
    p2: "Verantwortlich für die Verarbeitung Ihrer Daten ist die SOFITEX GROUP, eingetragen im Handelsregister LUXEMBOURG mit Sitz in der 11 Place Sts Pierre et Paul L-2334 LUXEMBOURG sowie alle ihre Tochtergesellschaften.",
    p3: "Ihre personenbezogenen Daten werden von SOFITEX GROUP und deren Tochtergesellschaften zum Zeitpunkt Ihrer Bewerbung, d. h. während des Einstellungsverfahrens, sowie bei Beendigung Ihres Einsatzes durch verschiedene Methoden erhoben, u. a. im Rahmen von Gesprächen, Schriftverkehr, Formularen, Fragebögen, Cookies und bei Dritten.",
    p4: "Die Verarbeitung erfolgt u. a. zu folgenden Zwecken (nicht erschöpfende Aufzählung): ",
    p5: "Um zu beurteilen, ob Sie über die fachlichen Voraussetzungen für die angebotene Stelle verfügen, um die für SOFITEX GROUP und deren Tochtergesellschaften geltenden gesetzlichen, vertraglichen und verordnungsrechtlichen Verpflichtungen einzuhalten, insbesondere, um die Bewerbungen und das Personal zu verwalten, den Einsatzvertrag, die Lohnzettel,  die daraus resultierenden Sozialversicherungsmeldungen zu erstellen und die entsprechenden Zahlungen vorzunehmen sowie um über die Informationen zu verfügen bzw. die Informationen liefern zu können, die für Ihre Auswahl für einen Einsatz bzw. den Austausch mit Ihnen bezüglich eines Einsatzvorschlags erforderlich sind.",
    p6: "Im Zusammenhang mit Ihrer Bewerbung sind – sofern nichts anderes bestimmt ist – sämtliche Pflichtangaben des Anmeldeformulars für die Bestätigung Ihrer Bewerbung und Beurteilung Ihrer fachlichen Eignung für die angebotene Stelle erforderlich.",
    p7: "SOFITEX GROUP und deren Tochtergesellschaften verarbeiten möglicherweise zu den oben genannten Zwecken Identitätsdaten, Daten wirtschaftlicher und finanzieller Art, gesundheitsbezogene Daten, Ihre Sozialversicherungsnummer und weitere personenbezogene und berufliche Informationen sowie bei spezifischen, bestimmten Vorschriften unterliegenden Stellen (wie Fahrzeugführer m/w, Buchhalter m/w, Gesundheits- und Krankenpfleger m/w usw.) auch Ihr Führungszeugnis.",
    p8: "Die Speicherfrist personenbezogener Daten beträgt in Bezug auf die Bewerberdaten in der Einstellungsphase maximal zwei (2) Jahre ab dem letzten Kontakt und in Bezug auf die Arbeitnehmerdaten maximal zehn (10) Jahre ab der letzten Gehaltszahlung; nach Ablauf dieser Speicherfrist werden die besagten Daten vernichtet oder anonymisiert. ",
    p9: "Ihre Daten werden möglicherweise zu den vorgenannten Zwecken an in der Europäischen Union ansässige Dritte oder Unterauftragnehmer (Bsp.: Sammelkasse für den Einzug der Sozialversicherungs- und Familienhilfebeiträge, Familienbeihilfekasse, Softwareunternehmen, usw.) übermittelt.",
    p10: "SOFITEX GROUP und deren Tochtergesellschaften haben die für die Übermittlung Ihrer personenbezogenen Daten erforderlichen Maßnahmen zur Gewährleistung der Sicherheit, Vertraulichkeit und Integrität getroffen.",
    p11: "Wenn personenbezogene Daten in Länder außerhalb der Europäischen Union und in Länder übermittelt werden, die von der Europäischen Union als Länder anerkannt sind, deren Gesetze einen angemessenen Schutz personenbezogener Daten gewährleisten, insbesondere bei der Verarbeitung von Daten von Nicht-EU-Bürgern (Expatriates), sind zusätzliche Absicherungsmaßnahmen wie der Abschluss von Standardvertragsklauseln zu treffen.",
    p12: "Sie können gegenüber SOFITEX GROUP und deren Tochtergesellschaften von Ihrem Recht Gebrauch machen, der Verwendung Ihrer personenbezogenen Daten zu widersprechen, sofern Sie einen berechtigten Grund angeben. Des Weiteren können Sie gegenüber SOFITEX GROUP und deren Tochtergesellschaften von Ihrem Recht auf Auskunft, Berichtigung und Löschung Ihrer personenbezogenen Daten Gebrauch machen sowie von Ihrem Recht, jederzeit Anordnungen im Hinblick auf die Speicherung, Löschung und Offenlegung Ihrer Daten nach Ihrem Tod festzulegen, diese zu ändern oder zu widerrufen.",
    p13: "Alle diese Rechte können schriftlich unter der oben genannten Postanschrift oder der E-Mail-Adresse",
    p14: "ausgeübt werden, wobei Sie Ihre Identität nachweisen müssen (Name, Vorname, Adresse, Ausweisnummer, Ausweiskopie).",
    brandsIntro: "Die Sofitex-Gruppe beschäftigt zurzeit 130 Festangestellte, beschäftigt täglich mehr als 3.000 Leiharbeiter bei 1.500 Kundenunternehmen und erzielt einen Umsatz von ungefähr 140 Millionen Euro.",
  },
    
    
};
  
export const LANG_NAMES = [
  { locale: 'en', name: 'EN' },
  { locale: 'fr', name: 'FR' },
  { locale: 'de', name: 'DE' },
]