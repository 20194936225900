import { Box, Heading, Text } from '@chakra-ui/react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import TranslationContainer from '../languages/Translation/TranslationContainer';
import { useEffect } from 'react';

export default function LegalInformation() {

  useEffect(() => {
    window.scrollTo(0,0);
  }, [])
  
    return (
      <>
        <Header page='' />
        <main>
          <Box width={{base:"100%", lg: "1024px"}} mx={'auto'} px={{base: 4, md: 8}} pt={{base: 10, md: 20}} pb={20}>
            <Heading><TranslationContainer translationKey="p1" /></Heading>
            <Text py={5} fontSize={'2xl'} fontWeight={'semibold'}><TranslationContainer translationKey="m2" /></Text>
            <Text pb={5}><TranslationContainer translationKey="m3" /> <a className='link' href='/'>www.sofitex-group.com</a> <TranslationContainer translationKey="m4" /></Text>
            <Text fontWeight={'bold'}>Sofitex Group</Text>
            <Text>11 Place Sts Pierre et Paul</Text>
            <Text>L-2334 LUXEMBOURG</Text>
            <Text><TranslationContainer translationKey="m5" /></Text>
            <Text><TranslationContainer translationKey="m6" /></Text>
            <Text py={5} fontSize={'2xl'} fontWeight={'semibold'} color={"#03b403"}><TranslationContainer translationKey="m7" /></Text>
            <Text className='link' as={'button'} onClick={() => window.open('https://www.ovhcloud.com/fr/')}>OVH</Text>
            <Text>2 rue Kellermann - 59100&nbsp;Roubaix&nbsp;-&nbsp;France</Text>
            <Text>Tel. : <a className='link' href='tel:+33972101007'>+33 (0)9 72 10 10 07</a></Text>
            <Text py={5} fontSize={'2xl'} fontWeight={'semibold'} color={"#03b403"}><TranslationContainer translationKey="m8" /></Text>
            <Text><TranslationContainer translationKey="m9" /></Text>
            <Text className='link' as={'button'} onClick={() => window.open('https://www.process-technology.eu/')}>PROCESS TECHNOLOGY</Text>
            <Text>36 avenue de la 1ère Dvision Blindée - 68100&nbsp;Mulhouse&nbsp;-&nbsp;France</Text>
            <Text>Tel. : <a className='link' href='tel:+33689647182'>+33 (0)6 89 64 71 82</a></Text>
            <Text py={5} fontSize={'2xl'} fontWeight={'semibold'}><TranslationContainer translationKey="m10" /></Text>
            <Text><TranslationContainer translationKey="m11" /> <a className='link' href='/'>www.sofitex-group.com</a> <TranslationContainer translationKey="m12" /> <a className='link' href='/'>www.sofitex-group.com</a> <TranslationContainer translationKey="m13" /></Text>
            <Text py={3}><TranslationContainer translationKey="m14" /></Text>
            <Text><TranslationContainer translationKey="m15" /> <a className='link' href='/'>www.sofitex-group.com</a> <TranslationContainer translationKey="m16" /></Text>
            <Text py={5} fontSize={'2xl'} fontWeight={'semibold'}><TranslationContainer translationKey="m17" /></Text>
            <Text><TranslationContainer translationKey="m18" /> <a className='link' href='/'>www.sofitex-group.com</a> <TranslationContainer translationKey="m19" /></Text>
            <Text py={3}><TranslationContainer translationKey="m20" /> <a className='link' href='/'>www.sofitex-group.com</a> <TranslationContainer translationKey="m21" /></Text>
            <Text><TranslationContainer translationKey="m22" /> <a className='link' href='/'>www.sofitex-group.com</a> <TranslationContainer translationKey="m23" /> <a className='link' href='/'>www.sofitex-group.com</a> <TranslationContainer translationKey="m24" /></Text>
            <Text py={5} fontSize={'2xl'} fontWeight={'semibold'}><TranslationContainer translationKey="m25" /></Text>
            <Text><TranslationContainer translationKey="m26" /></Text>
            <Text py={3}><TranslationContainer translationKey="m27" /></Text>
            <Text py={5} fontSize={'2xl'} fontWeight={'semibold'}><TranslationContainer translationKey="m28" /></Text>
            <Text><TranslationContainer translationKey="m29" /></Text>
            <Text py={3}><TranslationContainer translationKey="m30" /></Text>
            <Text><TranslationContainer translationKey="m31" /></Text>
            <Text py={5} fontSize={'2xl'} fontWeight={'semibold'}><TranslationContainer translationKey="m32" /></Text>
            <Text><TranslationContainer translationKey="m33" /> <a className='link' href='/'>www.sofitex-group.com</a> <TranslationContainer translationKey="m34" /></Text>
            <Text py={3}><TranslationContainer translationKey="m35" /> <a className='link' href='/'>www.sofitex-group.com</a>.</Text>
            <Text><TranslationContainer translationKey="m36" /></Text>
            <Text py={5} fontSize={'2xl'} fontWeight={'semibold'}><TranslationContainer translationKey="m37" /></Text>
            <Text><TranslationContainer translationKey="m38" /> <a className='link' href='/'>www.sofitex-group.com</a> <TranslationContainer translationKey="m39" /></Text>
          </Box>
          <Footer />
        </main>
      </>
    )
  
}