import { Box, Flex, Button, Image, Heading, Collapse, Text, Grid, GridItem } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import TranslationContainer from '../languages/Translation/TranslationContainer';

export default function Brands() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const [collapse1, setCollapse1] = useState(false)
    const [collapse2, setCollapse2] = useState(false)
    const [collapse3, setCollapse3] = useState(false)
    const [collapse4, setCollapse4] = useState(false)
    const [collapse5, setCollapse5] = useState(false)
    const [collapse6, setCollapse6] = useState(false)
    const [collapse7, setCollapse7] = useState(false)
    const [collapse8, setCollapse8] = useState(false)
    const [collapse9, setCollapse9] = useState(false)
    const [collapse10, setCollapse10] = useState(false)
    const [collapse11, setCollapse11] = useState(false)
    const [collapse12, setCollapse12] = useState(false)

    return (
        <>
        {/* <Head>
            <title>Create Next App</title>
            <meta name="description" content="Generated by create next app" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="icon" href="/favicon.ico" />
        </Head> */}
        <Header page='brands' />
        <main>
            <Box minHeight={{base: 'calc(100vh - 64px)', md: 'calc(100vh - 73px)'}} pb={20}>
                <Box display={{base: 'none', md: 'block'}} position={'relative'} bg={'gray.50'}>
                    <Image src={'/images/map_europe3.png'} alt={'europe'} />
                    <Flex alignItems={'center'} justify={'flex-end'} position={'absolute'} right={{md: 20, xl: 20}} top={0} height={'100%'}>
                        <Box>
                            <Image src={'/images/Sofitexgroup_solo.png'} width={{md: '400px', xl: '500px'}} alt={'europe'} />
                            <Heading fontSize={{md: '4xl', xl: '5xl'}} fontStyle={'italic'} maxWidth={{md: '400px', xl: '500px'}} color={'#03b403'}><TranslationContainer translationKey="international" /></Heading>
                        </Box>
                    </Flex>
                </Box>
                <Image display={{base: 'block', md: 'none'}} src={'/images/map_europe_mobile.png'} width={'100%'} alt={'europe'} />
                <Box width={{base:"100%", lg: "1024px"}} mx={'auto'} px={{base: 4, md: 8}}>
                    <Box display={{md: 'none'}} mt={5}>
                        <Image width={{base: '400px', sm: '400px'}} src={'/images/Sofitexgroup_solo.png'} alt={'europe'} />
                        <Heading fontSize={'3xl'} fontStyle={'italic'} color={'#03b403'}><TranslationContainer translationKey="international" /></Heading>
                    </Box>
                    <Text fontWeight={'semibold'} fontSize={'3xl'} my={{base: 10, md: 20}} textAlign={{md: 'justify'}}><TranslationContainer translationKey="brandsIntro" /></Text>
                    <Box mb={{base: 10, md: 20}}>
                        <Flex mt={{base: 10, lg: 20}} mb={{base: 10, lg: 20}} columnGap={2}>
                            <Box mt={{base: '0.4em', lg: '0.5em'}} borderLeft={'10px solid #03b403'} height={{base: '1.1em', lg: '1.4em'}}></Box>
                            <Heading fontSize={{base: '2xl', lg: '3xl'}}><TranslationContainer translationKey="france" /></Heading>
                        </Flex>
                        <Box width={'100%'}>
                            <Box as={'button'} onClick={() => setCollapse1(!collapse1)} borderLeft={'3px solid #03b403'} pl={5}>
                                <Image className='logo-animation' src={'/images/Sofitex_interim-au-cdi_SOLO_BLANC.png'} alt={'logo sofitex france'} height={{base: '50px', md: '70px'}} />
                            </Box>
                            <Text mt={2} pl={5} fontStyle={'italic'} fontSize={'xl'} maxWidth={'800px'}><TranslationContainer translationKey="textSofitexFrance" /></Text>
                            <Box pl={5}>
                                <Collapse in={collapse1} animateOpacity>
                                    <Button className='agency-link' variant={'unstyled'} onClick={() => window.open('https://sofitex.fr')}>www.sofitex.fr</Button>
                                    <Text my={5} fontWeight={'bold'} fontSize={'lg'}>Haut-Rhin</Text>
                                    <Grid templateColumns={'repeat(6, 1fr)'} rowGap={{base: 5, md: 10}} columnGap={5}>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.sofitex.fr/FR/agence-interim-travail-temporaire/agence-interim-sofitex-expert-colmar.html')}>Colmar</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="generalist" /></Text>
                                                <Text mt={2}>42 rue du Nord, 68000 Colmar</Text>
                                                <a className='agency-link' href='tel:+33389247890'>+33 (0)3 89 24 78 90</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:colmar@sofitex.fr'>colmar@sofitex.fr</a>
                                            </Box>
                                        </GridItem>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.sofitex.fr/FR/agence-interim-travail-temporaire/agence-interim-sofitex-guebwiller.html')}>Guebwiller</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="generalist" /></Text>
                                                <Text mt={2}>22 rue de la République, 68500 Guebwiller</Text>
                                                <a className='agency-link' href='tel:+33389286470'>+33 (0)3 89 28 64 70</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:guebwiller@sofitex.fr'>guebwiller@sofitex.fr</a>
                                            </Box>
                                        </GridItem>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.sofitex.fr/FR/agence-interim-travail-temporaire/agence-interim-sofitex-cernay.html')}>Cernay</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="generalist" /></Text>
                                                <Text mt={2}>1 rue des artisans, 68700 Cernay</Text>
                                                <a className='agency-link' href='tel:+33389397118'>+33 (0)3 89 39 71 18</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:cernay@sofitex.fr'>cernay@sofitex.fr</a>
                                            </Box>
                                        </GridItem>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.sofitex.fr/FR/agence-interim-travail-temporaire/agence-interim-sofitex-saint-louis.html')}>Saint-Louis</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="generalist" /></Text>
                                                <Text mt={2}>1 rue du Jura, 68300 Saint-Louis</Text>
                                                <a className='agency-link' href='tel:+33389899940'>+33 (0)3 89 89 99 40</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:stlouis@sofitex.fr'>stlouis@sofitex.fr</a>
                                            </Box>
                                        </GridItem>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.sofitex.fr/FR/agence-interim-travail-temporaire/agence-interim-sofitex-mulhouse-logistique.html')}>Mulhouse</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="logisticsAndTransport" /></Text>
                                                <Text mt={2}>21 rue de la Sinne, 68100 Mulhouse</Text>
                                                <a className='agency-link' href='tel:+33389365580'>+33 (0)3 89 36 55 80</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:mulhouselt@sofitex.fr'>mulhouselt@sofitex.fr</a>
                                            </Box>
                                        </GridItem>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.sofitex.fr/FR/agence-interim-travail-temporaire/agence-interim-sofitex-mulhouse-pros.html')}>Mulhouse</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="btpAndIndustry" /></Text>
                                                <Text mt={2}>8 rue Auguste Wicky, 68100 Mulhouse</Text>
                                                <a className='agency-link' href='tel:+33389660925'>+33 (0)3 89 66 09 25</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:mulhousepro@sofitex.fr'>mulhousepro@sofitex.fr</a>
                                            </Box>
                                        </GridItem>
                                    </Grid>
                                    <Text mt={10} mb={5} fontWeight={'bold'} fontSize={'lg'}>Bas-Rhin</Text>
                                    <Grid templateColumns={'repeat(6, 1fr)'} rowGap={{base: 5, md: 10}} columnGap={5}>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.sofitex.fr/FR/agence-interim-travail-temporaire/agence-interim-sofitex-strasbourg.html')}>Strasbourg</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="industry" />&nbsp;| <TranslationContainer translationKey="logistics" />&nbsp;| <TranslationContainer translationKey="events" /></Text>
                                                <Text mt={2}>10a rue de Molsheim, 67000 Strasbourg</Text>
                                                <a className='agency-link' href='tel:+33388113018'>+33 (0)3 88 11 30 18</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:strasbourg@sofitex.fr'>strasbourg@sofitex.fr</a>
                                            </Box>
                                        </GridItem>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.sofitex.fr/FR/agence-interim-travail-temporaire/agence-interim-sofitex-molsheim-ica.html')}>Molsheim</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="generalist" /></Text>
                                                <Text mt={2}>22 route Ecospace, 67120 Molsheim</Text>
                                                <a className='agency-link' href='tel:+33388955533'>+33 (0)3 88 95 55 33</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:molsheim@sofitex.fr'>molsheim@sofitex.fr</a>
                                            </Box>
                                        </GridItem>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.sofitex.fr/FR/agence-interim-travail-temporaire/agence-interim-sofitex-haguenau-ica.html')}>Haguenau</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="generalist" /></Text>
                                                <Text mt={2}>Place des Dominicains, 67500 Haguenau</Text>
                                                <a className='agency-link' href='tel:+33388053370'>+33 (0)3 88 05 33 70</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:haguenau@sofitex.fr'>haguenau@sofitex.fr</a>
                                            </Box>
                                        </GridItem>
                                    </Grid>
                                    <Text mt={10} mb={5} fontWeight={'bold'} fontSize={'lg'}>Moselle</Text>
                                    <Grid templateColumns={'repeat(6, 1fr)'} rowGap={{base: 5, md: 10}} columnGap={5}>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.sofitex.fr/FR/agence-interim-travail-temporaire/agence-interim-sofitex-hagondange.html')}>Hagondange</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="industry" /></Text>
                                                <Text mt={2}>5 rue du 8 mai 1945, 57300 Hagondange</Text>
                                                <a className='agency-link' href='tel:+33387709830'>+33 (0)3 87 70 98 30</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:hagondange@sofitex.fr'>hagondange@sofitex.fr</a>
                                            </Box>
                                        </GridItem>
                                    </Grid>
                                    <Text mt={10} mb={5} fontWeight={'bold'} fontSize={'lg'}>Doubs</Text>
                                    <Grid templateColumns={'repeat(6, 1fr)'} rowGap={{base: 5, md: 10}} columnGap={5}>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.sofitex.fr/FR/agence-interim-travail-temporaire/agence-interim-sofitex-expert-montbeliard.html')}>Montbéliard</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="generalist" /></Text>
                                                <Text mt={2}>15, rue de l'Etuve, 25200 Montbéliard</Text>
                                                <a className='agency-link' href='tel:+33381710052'>+33 (0)3 81 71 00 52</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:montbeliard@sofitex.fr'>montbeliard@sofitex.fr</a>
                                            </Box>
                                        </GridItem>
                                    </Grid>
                                </Collapse>
                            </Box>
                        </Box>
                        <Box mt={10} width={'100%'}>
                            <Box as={'button'} onClick={() => setCollapse2(!collapse2)} borderLeft={'3px solid #03b403'} pl={5}>
                                <Image className='logo-animation' src={'/images/Sofitex_Experts_SOLO_BLANC.png'} alt={'logo sofitex experts'} height={{base: '50px', md: '70px'}} />
                            </Box>
                            <Text mt={2} pl={5} fontStyle={'italic'} fontSize={'xl'} maxWidth={'800px'}><TranslationContainer translationKey="textSofitexExperts" /></Text>
                            <Box pl={5}>
                                <Collapse in={collapse2} animateOpacity>
                                    <Button className='agency-link' variant={'unstyled'} onClick={() => window.open('https://sofitex-experts.fr')}>www.sofitex-experts.fr</Button>
                                    <Text my={5} fontWeight={'bold'} fontSize={'lg'}>Haut-Rhin</Text>
                                    <Grid templateColumns={'repeat(6, 1fr)'} rowGap={{base: 5, md: 10}} columnGap={5}>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                        <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.sofitex.fr/FR/agence-interim-travail-temporaire/agence-interim-sofitex-mulhouse-expert.html')}>Mulhouse Experts</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="tertiaire" />&nbsp;| <TranslationContainer translationKey="technicien" />&nbsp;| <TranslationContainer translationKey="ingenieur" />&nbsp;| <TranslationContainer translationKey="cadres" /></Text>
                                                <Text mt={2}>55 rue Marc Seguin, 68200 Mulhouse</Text>
                                                <a className='agency-link' href='tel:+33389369050'>+33 (0)3 89 36 90 50</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:mulhouseexperts@sofitex.fr'>mulhouseexperts@sofitex.fr</a>
                                            </Box>
                                        </GridItem>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                        <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.sofitex.fr/FR/agence-interim-travail-temporaire/agence-interim-sofitex-saint-louis-experts.html')}>Saint-Louis Experts</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="tertiaire" />&nbsp;| <TranslationContainer translationKey="technicien" />&nbsp;| <TranslationContainer translationKey="ingenieur" />&nbsp;| <TranslationContainer translationKey="cadres" /></Text>
                                                <Text mt={2}>1 rue du Jura, 68300 Saint-Louis</Text>
                                                <a className='agency-link' href='tel:+33389899940'>+33 (0)3 89 89 99 40</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:stlouisexperts@sofitex.fr'>stlouisexperts@sofitex.fr</a>
                                            </Box>
                                        </GridItem>
                                    </Grid>
                                    <Text mt={10} mb={5} fontWeight={'bold'} fontSize={'lg'}>Bas-Rhin</Text>
                                    <Grid templateColumns={'repeat(6, 1fr)'} rowGap={{base: 5, md: 10}} columnGap={5}>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.sofitex.fr/FR/agence-interim-travail-temporaire/agence-interim-sofitex-strasbourg-expert.html')}>Strasbourg Experts</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="tertiaire" />&nbsp;| <TranslationContainer translationKey="technicien" />&nbsp;| <TranslationContainer translationKey="ingenieur" />&nbsp;| <TranslationContainer translationKey="cadres" /></Text>
                                                <Text mt={2}>20 rue Georges Wodli, 67000 Strasbourg</Text>
                                                <a className='agency-link' href='tel:+33388239120'>+33 (0)3 88 23 91 20</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:strasbourgexperts@sofitex.fr'>strasbourgexperts@sofitex.fr</a>
                                            </Box>
                                        </GridItem>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.sofitex.fr/FR/agence-interim-travail-temporaire/agence-interim-sofitex-molsheim-expert.html')}>Molsheim Experts</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="tertiaire" />&nbsp;| <TranslationContainer translationKey="technicien" />&nbsp;| <TranslationContainer translationKey="ingenieur" />&nbsp;| <TranslationContainer translationKey="cadres" /></Text>
                                                <Text mt={2}>22 route Ecospace, 67120 Molsheim</Text>
                                                <a className='agency-link' href='tel:+33785558974'>+33 (0)7 85 55 89 74</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:molsheimexperts@sofitex.fr'>molsheim-experts@sofitex.fr</a>
                                            </Box>
                                        </GridItem>
                                    </Grid>
                                    <Text mt={10} mb={5} fontWeight={'bold'} fontSize={'lg'}>Paris</Text>
                                    <Grid templateColumns={'repeat(6, 1fr)'} rowGap={{base: 5, md: 10}} columnGap={5}>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.sofitex-experts.fr/FR/agence-interim-travail-temporaire/agence-interim-paris-expert.html')}>Paris Experts</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="tertiaire" />&nbsp;| <TranslationContainer translationKey="technicien" />&nbsp;| <TranslationContainer translationKey="ingenieur" />&nbsp;| <TranslationContainer translationKey="cadres" /></Text>
                                                <Text mt={2}>7 rue Abel, 75012 Paris</Text>
                                                <a className='agency-link' href='tel:+33143445716'>+33 (0)1 43 44 57 16</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:parisexperts@sofitex.fr'>parisexperts@sofitex.fr</a>
                                            </Box>
                                        </GridItem>
                                    </Grid>
                                </Collapse>
                            </Box>
                        </Box>
                        <Box mt={10} width={'100%'}>
                            <Box as={'button'} onClick={() => setCollapse3(!collapse3)} borderLeft={'3px solid #03b403'} pl={5}>
                                <Image className='logo-animation' src={'/images/logo-maxiplan.png'} alt={'logo sofitex experts'} height={{base: '50px', md: '70px'}} />
                            </Box>
                            <Text mt={2} pl={5} fontStyle={'italic'} fontSize={'xl'} maxWidth={'800px'}><TranslationContainer translationKey="textMaxiplan" /></Text>
                            <Box pl={5}>
                                <Collapse in={collapse3} animateOpacity>
                                    <Button className='agency-link' variant={'unstyled'} onClick={() => window.open('https://maxiplan.fr')}>www.maxiplan.fr</Button>
                                    <Grid mt={5} templateColumns={'repeat(6, 1fr)'} rowGap={{base: 5, md: 10}} columnGap={5}>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                        <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.maxiplan.fr')}>Maxiplan</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="architectes" />&nbsp;| <TranslationContainer translationKey="ingenieurs" />&nbsp;| <TranslationContainer translationKey="projeteurs" />&nbsp;| <TranslationContainer translationKey="dessinateurs" />&nbsp;| <TranslationContainer translationKey="graphistes" /></Text>
                                                <Text mt={2}>7 rue Abel, 75012 Paris</Text>
                                                <a className='agency-link' href='tel:+33143445716'>+33 (0)1 43 44 57 16</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:maxiplan@maxiplan.fr'>maxiplan@maxiplan.fr</a>
                                            </Box>
                                        </GridItem>
                                    </Grid>
                                </Collapse>
                            </Box>
                        </Box>
                        <Box mt={10} width={'100%'}>
                            <Box as={'button'} onClick={() => setCollapse12(!collapse12)} borderLeft={'3px solid #03b403'} pl={5}>
                                <Image className='logo-animation' src={'/images/logo-process.svg'} alt={'logo sofitex experts'} height={{base: '50px', md: '70px'}} />
                            </Box>
                            <Text mt={2} pl={5} fontStyle={'italic'} fontSize={'xl'} maxWidth={'800px'}><TranslationContainer translationKey="textProcess" /></Text>
                            <Box pl={5}>
                                <Collapse in={collapse12} animateOpacity>
                                    <Button className='agency-link' variant={'unstyled'} onClick={() => window.open('https://www.process-technology.eu/')}>www.process-technology.eu</Button>
                                    <Grid mt={5} templateColumns={'repeat(6, 1fr)'} rowGap={{base: 5, md: 10}} columnGap={5}>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                        <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.process-technology.eu/')}>Paris</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="assistanceTechnique" />&nbsp;| <TranslationContainer translationKey="formation" /></Text>
                                                <Text mt={2}>7 rue Abel, 75012 Paris</Text>
                                                <a className='agency-link' href='tel:+33689847182'>+33 (0)6 89 84 71 82</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:priscilia.solle@process-technology.eu'>priscilia.solle@process-technology.eu</a>
                                            </Box>
                                        </GridItem>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                        <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.process-technology.eu/')}>Metz</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="controle" />&nbsp;| <TranslationContainer translationKey="tri" />&nbsp;| <TranslationContainer translationKey="retouche" />&nbsp;| <TranslationContainer translationKey="formation" /></Text>
                                                <Text mt={2}>1 rue Inoré FABBRI, 57365 Ennery</Text>
                                                <a className='agency-link' href='tel:+33786281852'>+33 (0)7 86 28 18 52</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:loic.pereira@process-technology.eu'>loic.pereira@process-technology.eu</a>
                                            </Box>
                                        </GridItem>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.process-technology.eu/')}>Guénange</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="controle" />&nbsp;| <TranslationContainer translationKey="tri" />&nbsp;| <TranslationContainer translationKey="retouche" />&nbsp;| <TranslationContainer translationKey="formation" /></Text>
                                                <Text mt={2}>42 boulevard Bellevue, 57310 Guénange</Text>
                                                <a className='agency-link' href='tel:+33786281852'>+33 (0)7 86 28 18 52</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:loic.pereira@process-technology.eu'>loic.pereira@process-technology.eu</a>
                                            </Box>
                                        </GridItem>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.process-technology.eu/')}>Mulhouse</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="assistanceTechnique" />&nbsp;| <TranslationContainer translationKey="formation" /></Text>
                                                <Text mt={2}>36 avenue de la 1ere Division Blindée, 68100 Mulhouse</Text>
                                                <a className='agency-link' href='tel:+33689847182'>+33 (0)6 89 84 71 82</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:priscilia.solle@process-technology.eu'>priscilia.solle@process-technology.eu</a>
                                            </Box>
                                        </GridItem>
                                    </Grid>
                                </Collapse>
                            </Box>
                        </Box>
                    </Box>
                    <Box mb={{base: 10, md: 20}}>
                        <Flex mt={{base: 10, lg: 10}} mb={{base: 10, lg: 20}} columnGap={2}>
                            <Box mt={{base: '0.4em', lg: '0.5em'}} borderLeft={'10px solid #03b403'} height={{base: '1.1em', lg: '1.4em'}}></Box>
                            <Heading fontSize={{base: '2xl', lg: '3xl'}}><TranslationContainer translationKey="luxembourg" /></Heading>
                        </Flex>
                        <Box mt={10} width={'100%'}>
                            <Box as={'button'} onClick={() => setCollapse4(!collapse4)} borderLeft={'3px solid #03b403'} pl={5}>
                                <Image className='logo-animation' src={'/images/Sofitex_interim-au-cdi_SOLO_BLANC.png'} alt={'logo sofitex luxembourg'} height={{base: '50px', md: '70px'}} />
                            </Box>
                            <Text mt={2} pl={5} fontStyle={'italic'} fontSize={'xl'} maxWidth={'800px'}><TranslationContainer translationKey="textSofitexLuxembourg" /></Text>
                            <Box pl={5}>
                                <Collapse in={collapse4} animateOpacity>
                                    <Button className='agency-link' variant={'unstyled'} onClick={() => window.open('https://sofitex.lu')}>www.sofitex.lu</Button>
                                    <Grid mt={5} templateColumns={'repeat(6, 1fr)'} rowGap={{base: 5, md: 10}} columnGap={5}>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.sofitex.lu/FR/agence-interim-travail-temporaire/agence-interim-sofitex-industrie-btp.html')}>Esch-sur-Alzette</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="btp" /></Text>
                                                <Text mt={2}>5 rue Luxembourg, L-4220 Esch S/Alzette</Text>
                                                <a className='agency-link' href='tel:+352547633'>+352 (0)54 76 33</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:esch@sofitex.lu'>esch@sofitex.lu</a>
                                            </Box>
                                        </GridItem>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.sofitex.lu/FR/agence-interim-travail-temporaire/agence-interim-esch-sur-alzette-industrie.html')}>Esch-sur-Alzette</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="industry" />&nbsp;| <TranslationContainer translationKey="services" />&nbsp;| <TranslationContainer translationKey="logistics" /></Text>
                                                <Text mt={2}>83 rue Dicks, L-4082 Esch-sur-Alzette</Text>
                                                <a className='agency-link' href='tel:+3522629521'>+352 (0)26 29 521</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:luxembourg@sofitex.lu'>luxembourg@sofitex.lu</a>
                                            </Box>
                                        </GridItem>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.sofitex.lu/FR/agence-interim-travail-temporaire/agence-interim-sofitex-wiltz.html')}>Wiltz</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="generalist" /></Text>
                                                <Text mt={2}>8 rue des tondeurs, L-9570 Wiltz</Text>
                                                <a className='agency-link' href='tel:+352547633300'>+352 (0)54 76 33 300</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:wiltz@sofitex.lu'>wiltz@sofitex.lu</a>
                                            </Box>
                                        </GridItem>
                                    </Grid>
                                </Collapse>
                            </Box>
                        </Box>
                        <Box mt={10} width={'100%'}>
                            <Box as={'button'} onClick={() => setCollapse5(!collapse5)} borderLeft={'3px solid #03b403'} pl={5}>
                                <Image className='logo-animation' src={'/images/Sofitex_Talent_SOLO_BLANC.png'} alt={'logo sofitex talent'} height={{base: '50px', md: '70px'}} />
                            </Box>
                            <Text mt={2} pl={5} fontStyle={'italic'} fontSize={'xl'} maxWidth={'800px'}><TranslationContainer translationKey="textSofitexTalent" /></Text>
                            <Box pl={5}>
                                <Collapse in={collapse5} animateOpacity>
                                    <Button className='agency-link' variant={'unstyled'} onClick={() => window.open('https://www.sofitex-talent.lu')}>www.sofitex-talent.lu</Button>
                                    <Grid mt={5} templateColumns={'repeat(6, 1fr)'} rowGap={{base: 5, md: 10}} columnGap={5}>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.sofitex.lu/FR/agence-interim-travail-temporaire/agence-interim-sofitex-talent.html')}>Sofitex Talent</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="finance" />&nbsp;| <TranslationContainer translationKey="office" />&nbsp;| <TranslationContainer translationKey="it" /></Text>
                                                <Text mt={2}>171 route d’Esch Immeuble Le Trianon, L-1471 Luxembourg</Text>
                                                <a className='agency-link' href='tel:+352262952400'>+352 (0)26 29 52 400</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:contact@sofitex-talent.lu'>contact@sofitex-talent.lu</a>
                                            </Box>
                                        </GridItem>
                                    </Grid>
                                </Collapse>
                            </Box>
                        </Box>
                        <Box mt={10} width={'100%'}>
                            <Box as={'button'} onClick={() => setCollapse6(!collapse6)} borderLeft={'3px solid #03b403'} pl={5}>
                                <Image className='logo-animation' src={'/images/transman.png'} alt={'logo transman'} height={{base: '50px', md: '70px'}} />
                            </Box>
                            <Text mt={2} pl={5} fontStyle={'italic'} fontSize={'xl'} maxWidth={'800px'}><TranslationContainer translationKey="textTransman" /></Text>
                            <Box pl={5}>
                                <Collapse in={collapse6} animateOpacity>
                                    <Button className='agency-link' variant={'unstyled'} onClick={() => window.open('https://transman-consulting.com')}>www.transman-consulting.com</Button>
                                    <Grid mt={5} templateColumns={'repeat(6, 1fr)'} rowGap={{base: 5, md: 10}} columnGap={5}>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://transman-consulting.com')}>Luxembourg</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="tertiaire" />&nbsp;| <TranslationContainer translationKey="banque" />&nbsp;| <TranslationContainer translationKey="finance" />&nbsp;| <TranslationContainer translationKey="assurances" /></Text>
                                                <Text mt={2}>6 rue Henri M. Schnadt, L-2530 Luxembourg</Text>
                                                <a className='agency-link' href='tel:+35226295233'><nobr>+352 (0)26 29 52 33</nobr></a>
                                                <br/>
                                                <a className='agency-link' href='mailto:luxembourg@transman-consulting.com'><nobr>luxembourg@transman-consulting.com</nobr></a>
                                            </Box>
                                        </GridItem>
                                    </Grid>
                                </Collapse>
                            </Box>
                        </Box>
                    </Box>
                    <Box mb={{base: 10, md: 20}}>
                        <Flex mt={{base: 10, lg: 10}} mb={{base: 10, lg: 20}} columnGap={2}>
                            <Box mt={{base: '0.4em', lg: '0.5em'}} borderLeft={'10px solid #03b403'} height={{base: '1.1em', lg: '1.4em'}}></Box>
                            <Heading fontSize={{base: '2xl', lg: '3xl'}}><TranslationContainer translationKey="allemagne" /></Heading>
                        </Flex>
                        <Box mt={10} width={'100%'}>
                            <Box as={'button'} onClick={() => setCollapse7(!collapse7)} borderLeft={'3px solid #03b403'} pl={5}>
                                <Image className='logo-animation' src={'/images/Sofitex_Personaldienstleistungen_SOLO_BLANC.png'} alt={'logo sofitex france'} height={{base: '50px', md: '70px'}} />
                            </Box>
                            <Text mt={2} pl={5} fontStyle={'italic'} fontSize={'xl'} maxWidth={'800px'}><TranslationContainer translationKey="textSofitexDE" /></Text>
                            <Box pl={5}>
                                <Collapse in={collapse7} animateOpacity>
                                    <Button className='agency-link' variant={'unstyled'} onClick={() => window.open('https://sofitex.de')}>www.sofitex.de</Button>
                                    <Grid mt={5} templateColumns={'repeat(6, 1fr)'} rowGap={{base: 5, md: 10}} columnGap={5}>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.sofitex.de/DE/niederlassungen/sofitex-gmbh-saarbrucken.html')}>Saarbrücken</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="industry" />&nbsp;| <TranslationContainer translationKey="chantier" />&nbsp;| <TranslationContainer translationKey="electricite" /></Text>
                                                <Text mt={2}>Pfählerstraße 17, 66128 Saarbrücken</Text>
                                                <a className='agency-link' href='tel:+49681588100'>+49 (0)6815 88 10 0</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:jlg@sofitex.de'>jlg@sofitex.de</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:sk@sofitex.de'>sk@sofitex.de</a>
                                            </Box>
                                        </GridItem>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.sofitex.de/DE/niederlassungen/sofitex-gmbh-kleinblittersdorf.html')}>Kleinblittersdorf</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="generalist" /></Text>
                                                <Text mt={2}>Elsässer Str. 74, 66271 Kleinblittersdorf</Text>
                                                <a className='agency-link' href='tel:+4968059425770'>+49 (0)6805 94 25 7 70</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:jm@sofitex.de'>jm@sofitex.de</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:dcs@sofitex.de'>dcs@sofitex.de</a>
                                            </Box>
                                        </GridItem>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.sofitex.de/DE/niederlassungen/sofitex-neunkirchen-2.html')}>Neunkirchen</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="generalist" /></Text>
                                                <Text mt={2}>Bahnhofstraße 40, 66538 Neunkirchen</Text>
                                                <a className='agency-link' href='tel:+4968219123401'>+49 (0)6821 91 23 40 1</a>
                                            </Box>
                                        </GridItem>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.sofitex.de/DE/niederlassungen/sofitex-personaldienstleistung-gmbh-achern.html')}>Kehl am Rhein</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}>Industrie&nbsp;| Logistique</Text>
                                                <Text mt={2}>Hauptstraße 1, 77694 Kehl am Rhein</Text>
                                                <a className='agency-link' href='tel:+4978519565721'>+49 (0)7851 95 65 7 21</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:js@sofitex.de'>js@sofitex.de</a>
                                            </Box>
                                        </GridItem>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.sofitex.de/DE/niederlassungen/sofitex-personaldienstleistung-gmbh-kehl.html')}>Kehl am Rhein</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="chantier" />&nbsp;| <TranslationContainer translationKey="electricite" /></Text>
                                                <Text mt={2}>Hauptstraße 1, 77694 Kehl am Rhein</Text>
                                                <a className='agency-link' href='tel:+497851956570'>+49 (0)7851 95 65 70</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:dd@sofitex.de'>dd@sofitex.de</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:ms@sofitex.de'>ms@sofitex.de</a>
                                            </Box>
                                        </GridItem>
                                    </Grid>
                                </Collapse>
                            </Box>
                        </Box>
                    </Box>
                    <Box mb={{base: 10, md: 20}}>
                        <Flex mt={{base: 10, lg: 10}} mb={{base: 10, lg: 20}} columnGap={2}>
                            <Box mt={{base: '0.4em', lg: '0.5em'}} borderLeft={'10px solid #03b403'} height={{base: '1.1em', lg: '1.4em'}}></Box>
                            <Heading fontSize={{base: '2xl', lg: '3xl'}}><TranslationContainer translationKey="suisse" /></Heading>
                        </Flex>
                        <Box mt={10} width={'100%'}>
                            <Box as={'button'} onClick={() => setCollapse8(!collapse8)} borderLeft={'3px solid #03b403'} pl={5}>
                                <Image className='logo-animation' src={'/images/Sofitex_interim au_fixe_SOLO_BLANC.png'} alt={'logo sofitex suisse'} height={{base: '50px', md: '70px'}} />
                            </Box>
                            <Text mt={2} pl={5} fontStyle={'italic'} fontSize={'xl'} maxWidth={'800px'}><TranslationContainer translationKey="textSofitexCH" /></Text>
                            <Box pl={5}>
                                <Collapse in={collapse8} animateOpacity>
                                    <Button className='agency-link' variant={'unstyled'} onClick={() => window.open('https://sofitex.ch')}>www.sofitex.ch</Button>
                                    <Grid mt={5} templateColumns={'repeat(6, 1fr)'} rowGap={{base: 5, md: 10}} columnGap={5}>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.sofitex.ch/FR/agence-interim-travail-temporaire/sofitex-delemont.html')}>Delémont</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="industry" />&nbsp;| <TranslationContainer translationKey="btp" />&nbsp;| <TranslationContainer translationKey="tertiaire" /></Text>
                                                <Text mt={2}>Rue de la Molière 22, 2800 Delémont</Text>
                                                <a className='agency-link' href='tel:+41324211190'>+41 (0)32 421 11 90</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:info@sofitex.ch'>info@sofitex.ch</a>
                                            </Box>
                                        </GridItem>
                                    </Grid>
                                </Collapse>
                            </Box>
                        </Box>
                        <Box mt={10} width={'100%'}>
                            <Box as={'button'} onClick={() => setCollapse9(!collapse9)} borderLeft={'3px solid #03b403'} pl={5}>
                                <Image className='logo-animation' src={'/images/axellia.png'} alt={'logo axellia'} height={{base: '60px', md: '110px'}} />
                            </Box>
                            <Text mt={2} pl={5} fontStyle={'italic'} fontSize={'xl'} maxWidth={'800px'}><TranslationContainer translationKey="textAxellia" /></Text>
                            <Box pl={5}>
                                <Collapse in={collapse9} animateOpacity>
                                    <Button className='agency-link' variant={'unstyled'} onClick={() => window.open('https://www.axellia.ch/')}>www.axellia.ch</Button>
                                    <Grid mt={5} templateColumns={'repeat(6, 1fr)'} rowGap={{base: 5, md: 10}} columnGap={5}>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.axellia.ch/')}>Delémont</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="generalist" /></Text>
                                                <Text mt={2}>Rue de la Vauche 6, 2800 Delémont </Text>
                                                <a className='agency-link' href='tel:+41325520390'>+41 (0)32 552 03 90</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:info@axellia.ch'>info@axellia.ch</a>
                                            </Box>
                                        </GridItem>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.axellia.ch/')}>Boncourt</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="generalist" /></Text>
                                                <Text mt={2}>Chemin du Crêt des Pierres 3, 2926 Boncourt</Text>
                                                <a className='agency-link' href='tel:+41325520395'>+41 (0)32 552 03 95</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:info@axellia.ch'>info@axellia.ch</a>
                                            </Box>
                                        </GridItem>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.axellia.ch/')}>Saignelégier</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="generalist" /></Text>
                                                <Text mt={2}>Rue de la Gruère 7, 2350 Saignelégier</Text>
                                                <a className='agency-link' href='tel:+41329525100'>+41 (0)32 952 51 00</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:info@axellia.ch'>info@axellia.ch</a>
                                            </Box>
                                        </GridItem>
                                    </Grid>
                                </Collapse>
                            </Box>
                        </Box>
                        <Box mt={10} width={'100%'}>
                            <Box as={'button'} onClick={() => setCollapse10(!collapse10)} borderLeft={'3px solid #03b403'} pl={5}>
                                <Image className='logo-animation' src={'/images/paxsajob.png'} alt={'logo paxsajob'} height={{base: '50px', md: '70px'}} />
                            </Box>
                            <Text mt={2} pl={5} fontStyle={'italic'} fontSize={'xl'} maxWidth={'800px'}><TranslationContainer translationKey="textPaxsajob" /></Text>
                            <Box pl={5}>
                                <Collapse in={collapse10} animateOpacity>
                                    <Button className='agency-link' variant={'unstyled'} onClick={() => window.open('https://paxsajob.ch')}>www.paxsajob.ch</Button>
                                    <Grid mt={5} templateColumns={'repeat(6, 1fr)'} rowGap={{base: 5, md: 10}} columnGap={5}>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://paxsajob.ch')}>Biel/Bienne</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="generalist" /></Text>
                                                <Text mt={2}>Boulevard des Sports 18 – 20, 2504 Biel/Bienne</Text>
                                                <a className='agency-link' href='tel:+41323414040'>+41 (0)32 341 40 40</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:info@paxsajob.ch'>info@paxsajob.ch</a>
                                            </Box>
                                        </GridItem>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://paxsajob.ch')}>Tramelan</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="generalist" /></Text>
                                                <Text mt={2}>Grand-Rue 127, 2720 Tramelan</Text>
                                                <a className='agency-link' href='tel:+41324875858'>+41 (0)32 487 58 58</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:info@paxsajob.ch'>info@paxsajob.ch</a>
                                            </Box>
                                        </GridItem>
                                    </Grid>
                                </Collapse>
                            </Box>
                        </Box>
                    </Box>
                    <Box mb={{base: 10, md: 20}}>
                        <Flex mt={{base: 10, lg: 10}} mb={{base: 10, lg: 20}} columnGap={2}>
                            <Box mt={{base: '0.4em', lg: '0.5em'}} borderLeft={'10px solid #03b403'} height={{base: '1.1em', lg: '1.4em'}}></Box>
                            <Heading fontSize={{base: '2xl', lg: '3xl'}}><TranslationContainer translationKey="belgique" /></Heading>
                        </Flex>
                        <Box mt={10} width={'100%'}>
                            <Box as={'button'} onClick={() => setCollapse11(!collapse11)} borderLeft={'3px solid #03b403'} pl={5}>
                                <Image className='logo-animation' src={'/images/Sofitex_interim-au-cdi_SOLO_BLANC.png'} alt={'logo sofitex belgique'} height={{base: '50px', md: '70px'}} />
                            </Box>
                            <Text mt={2} pl={5} fontStyle={'italic'} fontSize={'xl'} maxWidth={'800px'}><TranslationContainer translationKey="textSofitexBE" /></Text>
                            <Box pl={5}>
                                <Collapse in={collapse11} animateOpacity>
                                    <Button className='agency-link' variant={'unstyled'} onClick={() => window.open('https://sofitex.be')}>www.sofitex.be</Button>
                                    <Grid templateColumns={'repeat(6, 1fr)'} rowGap={{base: 5, md: 10}} columnGap={5}>
                                        <GridItem colSpan={{base: 6, sm: 3, lg: 2}}>
                                            <Box as={'button'} fontWeight={'bold'} color={'#03b403'} onClick={() => window.open('https://www.sofitex.be/FR/agence-interim-travail-temporaire/agence-interim-sofitex-libramont-chevigny.html')}>Libramont-Chevigny</Box>
                                            <Box fontSize={'sm'}>
                                                <Text fontWeight={'semibold'} color={'gray.800'}><TranslationContainer translationKey="generalist" /></Text>
                                                <Text mt={2}>Avenue de Bouillon 37, 6800 Libramont-Chevigny</Text>
                                                <a className='agency-link' href='tel:+3261395037'>+32 (0)61 39 50 37</a>
                                                <br/>
                                                <a className='agency-link' href='mailto:libramont@sofitex.be'>libramont@sofitex.be</a>
                                            </Box>
                                        </GridItem>
                                    </Grid>
                                </Collapse>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Footer />
        </main>
        </>
    )
}